// Features.js

import React from "react";
// import "./Features.css"; // Import CSS file for styling

const Features = () => {
  return (
    <div className="huge-container">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="feature-text pe-5">
              <h3>Uncover Our Unique Advantages</h3>
              <p>
                Built on the principles of security, reliability, and
                innovation
              </p>
            </div>
            {/* <div className="col-lg-12">
              <a href="#" className="btn btn-primary btn-lg me-3">
                Sign up Today
              </a>
            
            </div> */}
          </div>
          <div className="col-lg-4">
            <ul class="feature-list">
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">Cutting-Edge Technology</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>{" "}
                </span>
                <span class="feature-list-text">Diverse Asset Selection</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>{" "}
                </span>
                <span class="feature-list-text">Intuitive User Interface</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>{" "}
                </span>
                <span class="feature-list-text">Security and Trust</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>{" "}
                </span>
                <span class="feature-list-text">
                  Exceptional Customer Support
                </span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">
                  Low Fees, High Performance
                </span>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul class="feature-list">
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">
                  Wide Range of Cryptocurrencies
                </span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">Secure Trading Platform</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">Advanced Trading Tools</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">
                  Instant Deposits and Withdrawals
                </span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">Mobile Accessibility</span>
              </li>
              <li class="feature-list-item">
                <span class="feature-list-icon">
                  <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>
                </span>
                <span class="feature-list-text">Regulatory Compliance</span>
              </li>
            </ul>
          </div>
          {/* <div className="col-lg-8">
          <div className="row spacer">
         
          </div>
          </div> */}
        </div>
      </div>

      {/* <div className="main-content">
       
      </div> */}
    </div>
  );
};

export default Features;
