import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import {
  N_getHarvestHistory,
  N_getStakeHistory,
} from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import { RiArrowUpDownFill } from "react-icons/ri";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";

function StakingHistory(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const token = user?.params ? user.params.token : user.token;
  const [history, setHistory] = useState(null);
  const [stakeHistory, setStakeHistory] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc"); // Default sorting order
  const [dateOrder, setDateOrder] = useState("desc");
  const [periodOrder, setPeriodOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // Adjust this value based on your preference\
  const [currentStakePage, setCurrentStakePage] = useState(1);
  const [totalStakePages, setTotalStakePages] = useState(1);
  const stakePageSize = 10; // Adjust this value based on your preference\

  const [activeTab, setActiveTab] = useState("harvest_history");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // You can add additional logic here if needed
  };

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  useEffect(() => {
    N_getHarvestHistory(token, currentPage).then((d) => {
      if (d.status === 200) {
        setHistory(d.stake_data);
        setTotalPages(Math.ceil(d.total / pageSize));
      }
    });
  }, [currentPage]);

  useEffect(() => {
    N_getStakeHistory(token).then((d) => {
      if (d.status === 200) {
        if (d.stake_data) {
          setStakeHistory(d.stake_data);
        }
      }
    });
  }, [currentStakePage]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 0) return;
    setCurrentPage(pageNumber);
  };

  const handleStakePageChange = (pageNumber) => {
    if (pageNumber < 0) return;
    setCurrentStakePage(pageNumber);
  };

  const handleFilteredData = () => {
    if (history && history.length > 0) {
      const sortedHistory = [...history].sort((a, b) => {
        if (sortOrder === "asc") {
          return a.harvest - b.harvest;
        } else {
          return b.harvest - a.harvest;
        }
      });

      setHistory(sortedHistory);
      // Toggle sorting order for the next click
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    }
  };

  const handleFilteredDateData = () => {
    if (history && history.length > 0) {
      const sortedDateHistory = [...history].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        if (dateOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setHistory(sortedDateHistory);
      // Toggle sorting order for the next click
      setDateOrder(dateOrder === "asc" ? "desc" : "asc");
    }
  };

  const handleFilteredPeriodData = () => {
    if (history && history.length > 0) {
      const sortedPeriodHistory = [...history].sort((a, b) => {
        const periodA = parseInt(a.invest_time);
        const periodB = parseInt(b.invest_time);

        if (periodOrder === "asc") {
          return periodA - periodB;
        } else {
          return periodB - periodA;
        }
      });

      setHistory(sortedPeriodHistory);
      // Toggle sorting order for the next click
      setPeriodOrder(periodOrder === "asc" ? "desc" : "asc");
    }
  };

  return (
    <>
      <Header {...props} />

      <>
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="activity_panel mb-4">
              <div className="tab_width">
                <div className="custom_pills">
                  <div className="row">
                    <div className="nav nav-pills" id="nav-tab" role="tablist">
                      <div
                        className={`nav-item nav-link ${
                          activeTab === "harvest_history" ? "active" : ""
                        } fs-14`}
                        style={{ height: "35px", whiteSpace: "nowrap" }}
                        onClick={() => handleTabClick("harvest_history")}
                      >
                        Interest Withdraw History
                      </div>
                      <div
                        className={`nav-item nav-link ${
                          activeTab === "staking_history" ? "active" : ""
                        } fs-14`}
                        style={{ height: "35px" }}
                        onClick={() => handleTabClick("staking_history")}
                      >
                        Staking History
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              activeTab === "harvest_history" ? "show active" : ""
            }`}
            id="harvest_history"
          >
            {" "}
            {history && history.length > 0 ? (
              <>
                <div className="overflow-auto mt-3 mx-5">
                  <div className="table_scroll_div">
                    <table className="table global_table table-bordered">
                      <thead>
                        <tr className="text-center">
                          <th>No.</th>
                          <th>Wallet Type</th>
                          <th>Staking Source</th>
                          <th>
                            Interest Amount
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredData}
                            />
                          </th>
                          <th>
                            Investment Period
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredPeriodData}
                            />
                          </th>
                          <th>
                            Deposited Amount
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredDateData}
                            />
                          </th>
                          <th>
                            Deposited At
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredDateData}
                            />
                          </th>
                          <th>
                            Withdraw At
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredDateData}
                            />
                          </th>
                          <th>
                            Earnings at Maturity
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredDateData}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {history && history.length > 0
                          ? history.map((item, key) => {
                              return (
                                <tr>
                                  <td className="text-center">{key + 1}</td>
                                  <td className="text-center">
                                    {item.wallet_type}
                                  </td>
                                  <td className="text-center">
                                    {item.StakingSource.toUpperCase()}
                                  </td>
                                  <td className="text-center">
                                    {item.harvest}
                                  </td>
                                  <td className="text-center">
                                    {item.invest_time} days
                                  </td>
                                  <td className="text-center">
                                    {item.staked_amount}
                                  </td>
                                  <td className="text-center">
                                    {new Date(
                                      item.depositedAt
                                    ).toLocaleDateString("en-IN", options)}
                                  </td>
                                  <td className="text-center">
                                    {new Date(
                                      item.createdAt
                                    ).toLocaleDateString("en-IN", options)}
                                  </td>
                                  <td className="text-center">
                                    {item.MaturityEarning} Bitbse
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                <nav aria-label="Page">
                  <ul className="pagination justify-content-center mt-3">
                    <li className="page-item">
                      <a
                        className="page-link bg-success"
                        href="#"
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <span className="text-black" aria-hidden="true">
                          &laquo;
                        </span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {/* Page Number  */}
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li className="page-item">
                      <a
                        className="page-link bg-success"
                        href="#"
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <span className="text-black" aria-hidden="true">
                          &raquo;
                        </span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </>
            ) : (
              <div className="container mt-4">
                <div className="alert alert-danger text-center text-black">
                  <strong>No Harvest History Found</strong>
                </div>
              </div>
            )}
          </div>

          <div
            className={`tab-pane fade ${
              activeTab === "staking_history" ? "show active" : ""
            }`}
            id="staking_history"
          >
            {stakeHistory && stakeHistory.length > 0 ? (
              <>
                <div className="table_scroll_div">
                  <div className="overflow-auto mt-3 mx-5">
                    <table className="table global_table table-bordered">
                      <thead>
                        <tr className="text-center">
                          <th>No.</th>
                          <th>Wallet Type</th>
                          <th>Invested Amount</th>
                          <th>Percentage</th>
                          <th>
                            Investment Period
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredPeriodData}
                            />
                          </th>
                          <th>
                            Deposited At
                            <RiArrowUpDownFill
                              className="text-muted"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilteredDateData}
                            />
                          </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stakeHistory && stakeHistory.length > 0
                          ? stakeHistory.map((item, key) => {
                              return (
                                <tr className="text-center">
                                  <td>{key + 1}</td>
                                  <td>{item.wallet_type}</td>
                                  <td>{item.invest_amount}</td>
                                  <td>{item.percent} %</td>
                                  <td>{item.invest_time}</td>
                                  <td>
                                    {new Date(
                                      item.createdAt
                                    ).toLocaleDateString("en-IN", options)}
                                  </td>
                                  <td>
                                    {!item.stake_completed === false ? (
                                      <button className="btn bg-danger bg-gradient text-body">
                                        CLOSE
                                      </button>
                                    ) : (
                                      <button className="btn bg-success bg-gradient text-body">
                                        OPEN
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>

                <nav aria-label="Page">
                  <ul className="pagination justify-content-center mt-3">
                    <li className="page-item">
                      <a
                        className="page-link bg-success"
                        href="#"
                        aria-label="Previous"
                        onClick={() =>
                          handleStakePageChange(currentStakePage - 1)
                        }
                        disabled={currentStakePage === 1}
                      >
                        <span className="text-black" aria-hidden="true">
                          &laquo;
                        </span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {/* Page Number  */}
                    {Array.from({ length: totalStakePages }, (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentStakePage === index + 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handleStakePageChange(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li className="page-item">
                      <a
                        className="page-link bg-success"
                        href="#"
                        aria-label="Next"
                        onClick={() => handleStakePageChange(currentPage + 1)}
                        disabled={currentPage === totalStakePages}
                      >
                        <span className="text-black" aria-hidden="true">
                          &raquo;
                        </span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </>
            ) : (
              <div className="container mt-4">
                <div className="alert alert-danger text-center text-black">
                  <strong>No Stake History Found</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
}

export default StakingHistory;
