import React from "react";

export default function FaqPage() {
  return (
    <div className="spacer">
      <div className="container">
        <div className="row">
          <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container">
              <h2 className="fw-bold">Frequently Asked Questions</h2>
              <h3 className="text-left fs-5">You can view your FAQ here!</h3>

              <div className="mx-auto col-xl-12 col-lg-12 my-5">
                <div class="nav nav-tabs my-3" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="nav-tab1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab1"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab1"
                    aria-selected="true"
                  >
                    Platform Usage
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab2-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab2"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab2"
                    aria-selected="false"
                  >
                    Security and Support
                  </button>
                </div>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="nav-tab1"
                  role="tabpanel"
                  aria-labelledby="nav-tab1-tab"
                >
                  <div className="row mt-5">
                    <div class="accordion accordion-flush" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What is Bitbse Exchange?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Bitbse Exchange is a cryptocurrency exchange
                              platform where users can trade various digital
                              assets such as Bitcoin, Ethereum, Litecoin, and
                              more.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How do I get started on Bitbse Exchange?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To get started, you need to sign up for an account
                            on the Bitbse Exchange website or mobile app. Once
                            your account is created, you can deposit funds and
                            start trading cryptocurrencies.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            What cryptocurrencies can I trade on bitbse?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Bitbse Exchange supports a wide range of
                            cryptocurrencies, including popular ones like
                            Bitcoin (BTC), Ethereum (ETH), Ripple (XRP),
                            Litecoin (LTC), and many others.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            How can I deposit funds into my Bitbse Exchange
                            account?
                          </button>
                        </h2>
                        <div
                          id="collapse4"
                          class="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            You can deposit funds into your Bitbse Exchange
                            account using various methods such as bank
                            transfers, credit/debit cards, and cryptocurrency
                            deposits.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            What cryptocurrencies can I trade on Bitbse
                            Exchange?
                          </button>
                        </h2>
                        <div
                          id="collapse5"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Bitbse Exchange supports a wide range of
                            cryptocurrencies, including popular ones like
                            Bitcoin (BTC), Ethereum (ETH), Ripple (XRP),
                            Litecoin (LTC), and many others.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            How can I withdraw funds from my bitbse account?
                          </button>
                        </h2>
                        <div
                          id="collapse6"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            You can withdraw funds from your bitbse account by
                            initiating a withdrawal request through the
                            platform. Withdrawal methods may include bank
                            transfers and cryptocurrency withdrawals.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse8"
                            aria-expanded="false"
                            aria-controls="collapse8"
                          >
                            What is the customer support process like on Bitbse
                            Exchange?
                          </button>
                        </h2>
                        <div
                          id="collapse8"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Bitbse Exchange provides customer support to assist
                            users with any issues or inquiries they may have.
                            You can typically reach customer support through
                            email, live chat, or support tickets.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab2"
                  role="tabpanel"
                  aria-labelledby="nav-tab2-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse21"
                          aria-expanded="false"
                          aria-controls="collapse21"
                        >
                          Is bitbse safe and secure?
                        </button>
                      </h2>
                      <div
                        id="collapse21"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, bitbse takes security seriously and employs
                          various measures to protect users' funds and personal
                          information. These measures may include encryption,
                          two-factor authentication, and regular security
                          audits.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse23"
                          aria-expanded="false"
                          aria-controls="collapse23"
                        >
                          Does Bitbse Exchange offer any educational resources
                          for beginners?
                        </button>
                      </h2>
                      <div
                        id="collapse23"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, Bitbse Exchange may offer educational resources
                          such as tutorials, guides, and blog posts to help
                          beginners learn about cryptocurrency trading and the
                          bitbse platform.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse24"
                          aria-expanded="false"
                          aria-controls="collapse24"
                        >
                          What security measures does Bitbse Exchange employ to
                          protect user funds?
                        </button>
                      </h2>
                      <div
                        id="collapse24"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Bitbse Exchange takes security seriously and
                          implements robust measures to safeguard user funds,
                          including encryption, two-factor authentication (2FA),
                          cold storage for the majority of funds, and regular
                          security audits.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-sm"></div>

              {/* <div className="my-5 text-center">
                <h5>
                  Can't find it here? <a href="#">Tell us your query here.</a>
                </h5>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
