import React, { useState, useEffect } from "react";

export default function PageNotFound() {
  return (
    <>
    
        <div className="page404 d-flex align-items-center justify-content-center text-center">
          <div>            
            <h1>Sorry, the page not found</h1>
            <p>
              The link you followed probably broken or the page has been removed
            </p>
            <a href="/" className="btn btn-primary btn-rounded">
              Go to Home Page
            </a>
          </div>
        </div>
    
      <div className="img_404">
      
        <img src="/img/404.png" />
      </div>
    </>
  );
}
