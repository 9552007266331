import historyProvider from "./historyProvider";
// import moment from "moment";
// import { orderBookSocketUrl } from "src/Apiconfigs/config";
const supportedResolutions = ["60", "120", "240", "360", "1D", "1W"];

const config = {
  supported_resolutions: supportedResolutions,
};
let web;
export default {
  // eslint-disable-line import/no-anonymous-default-export
  onReady: (cb) => {
    // console.log("=====onReady running");
    setTimeout(() => cb(config), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log("====Search Symbols running");
  },
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    var descriptionTemp = symbolName.split("/").join("").toUpperCase(); //-
    var symbol_stub = {
      name: descriptionTemp,
      description: "",
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      ticker: symbolName,
      // exchange: split_data[0],
      minmov: 1,
      pricescale: 100,
      minmove2: 1,
      has_intraday: true,
      intraday_multipliers: ["15"],
      supported_resolution: supportedResolutions,
      volume_precision: 8,
      data_status: "streaming",
      has_empty_bars: true,
    };

    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },
  getBars: async function (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) {
    try {
      const bars = await historyProvider.getBars(
        symbolInfo,
        resolution,
        from,
        to,
        firstDataRequest
      );
      if (bars?.length) {
        onHistoryCallback(bars, { noData: false });
      }
    } catch (error) {
      console.log("bars--error", error);
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    // const name = window.localStorage.getItem("symbol")?.split("/")[0];
    // web = new WebSocket(orderBookSocketUrl);
    // web.onopen = () => {
    //   const dataToSend = {
    //     requestType: "pair_latest_market_data",
    //     symbol: name,
    //   };
    //   web.send(JSON.stringify(dataToSend));
    //   web.onmessage = async (event) => {
    //     let obj = JSON.parse(event.data);
    //     // console.log("event", obj.responseResult);
    //     const socketData = obj.responseResult;
    //     const newBars = {
    //       time: moment(new Date()).unix() * 1000, //TradingView requires bar time in ms
    //       low: socketData.low,
    //       high: socketData.high,
    //       open: socketData.open,
    //       close: socketData.close,
    //       volume: socketData.volume,
    //     };
    //     onRealtimeCallback(newBars, { noData: true });
    //     // console.log("newBars", newBars);
    //   };
    //   web.onerror = (err) => {};
    // };
  },
  unsubscribeBars: (subscriberUID) => {
    // console.log("=====unsubscribeBars running");
    web.close();
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    // console.log("=====calculateHistoryDepth running resolution0", resolution);
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : undefined;
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    // console.log("=====getMarks running");
  },
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    //optional
    // console.log("=====getTimeScaleMarks running");
  },
  getServerTime: (cb) => {
    // console.log("=====getServerTime running");
  },
};
