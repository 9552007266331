import {
  SET_USER_BALANCE,
  SET_USER_ORDER_PENDING,
  SET_USER_ORDER_CLOSE,
  SET_USER_FAV_PAIRING,
  SET_USER_FAV_CURRENCY_RATE,
  SET_USER_FAV_CURRENCY,
  SWITCH_THEME,
  SET_FUTURE_ORDER,
  SET_FUTURE_POSITION,
  SET_FUTURE_OPEN,
  SET_USER_FUTURE_BALANCE,
} from "../constant";
import { N_completeOrder, N_get_future_order, N_get_wallet, N_get_walletfuture, N_OrdersHistory, N_pendingOrder } from "../helpers/api_functions_new";

// export function getData() {
//   return (dispatch) =>
//     getCoinsData()
//       .then((data) => {
//         // console.log(data);
//         dispatch({ type: GET_COIN_DATA, data: data, coin_loading: false });
//         return data;
//       })
//       .catch((e) => e);
// }

// export function getGraphData(currency_type, compare_currency, interval) {
//   return (dispatch) =>
//     getChartData(currency_type, compare_currency, interval)
//       .then((data) => {
//         dispatch({ type: GET_CURRENCY_DATA, data: Object.values(data) });
//         return data;
//       })
//       .catch((e) => e);
// }

export function getUserBalance(token) {
  return (dispatch) => {
    N_get_wallet(token).then((d) => {
      dispatch({
        type: SET_USER_BALANCE,
        data: Object.values(d.params.wallets),
        wallet_loading: false,
      });
    });
  };
}

export function getFutureUserBalance(token) {
  return (dispatch) => {
    N_get_walletfuture(token).then((d) => {
      dispatch({
        type: SET_USER_FUTURE_BALANCE,
        data: Object.values(d.params.wallets)
      });
    });
  };
}

export function getFutureUserOrder(token) {
  return (dispatch) => {
        N_get_future_order(
          token,
          "open" 
        )
          .then((res) => {
            dispatch({
              type: SET_FUTURE_OPEN,
              data: res.order
            });
          })
          .catch((e) => console.log(e));
      
        N_get_future_order(
          token,
          "order"
        )
          .then((res) => {
            dispatch({
              type: SET_FUTURE_ORDER,
              data: res.order
            });
          })
          .catch((e) => console.log(e));
      
        N_get_future_order(
          token,
          "position"
        )
          .then((res) => {
            dispatch({
              type: SET_FUTURE_POSITION,
              data: res.order
            });
          })
          .catch((e) => console.log(e));
  };
}

export function getUserOrder(token, currency_type, compare_currency) {
  return (dispatch) => {
    N_pendingOrder(token, currency_type, compare_currency)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: SET_USER_ORDER_PENDING,
            data: res.params.trade_history.pending,
            pending_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
      N_completeOrder(token, currency_type, compare_currency)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: SET_USER_ORDER_CLOSE,
            data: res.params.trade_history.compleated,
            close_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
  };
}

// export function getOrderBook(currency_type, compare_currency, cb, action) {
//   return (dispatch) => {
//     getAllOrderBook(currency_type, compare_currency, action)
//       .then((d) => {
//         dispatch({ type: SET_ORDER_BOOK, data: d, order_book_loading: false }); //kjjk
//         cb();
//       })
//       .catch((e) => {
//         console.log(e);
//         cb();
//       });
//   };
// }

// export function getTradeHist(currency_type, compare_currency, cb, action) {
//   return (dispatch) => {
//     getTradeHistory(action, "", currency_type, compare_currency)
//       .then((d) => {
//         dispatch({ type: SET_TRADE_HISTORY, data: d, trade_loading: false });
//         cb();
//       })
//       .catch((e) => {
//         console.log(e);
//         cb();
//       });
//   };
// }

export function toggleFav(token, pair) {
  return (dispatch) => {
    
  };
}

export function viewFav(token) {
  return (dispatch) => {
   
  };
}

export function setFavCurrency(currency, cb) {
  return (dispatch) => {
    dispatch({ type: SET_USER_FAV_CURRENCY, data: currency });
    cb();
  };
}

export function setFavCurrencyRate(rate, cb) {
  return (dispatch) => {
    dispatch({ type: SET_USER_FAV_CURRENCY_RATE, data: rate });
    cb();
  };
}
export function switchTheme(theme_name) {
  return (dispatch) => {
    dispatch({ type: SWITCH_THEME, data: theme_name });
  };
}
