// HeroSection.js

import React from "react";
import "./HeroSection.css"; // Import CSS file for styling
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";

const HeroSection = () => {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  let coins1 = Object.values(coins);
  const market_child =
    coins1 &&
    coins1.map((item) => {
      if (item.symbol != "INR")
        return (
          <>
            <div className="img_backdrop">
              <img src={item.icon} />
            </div>
          </>
        );
    });
  return (
    <div className="main-content-fold">
      <div className="container">
        <div className="row align-items-center-- mt-5">
          <div className="col-lg-6">
            <img
              src="/img/home-img.png"
              className="img-fluid d-none d-lg-block"
            />
          </div>
          <div className="col-lg-6">
            <div className="hero-text">
              <h1>The forefront of currency evolution has arrived.</h1>
              <p>
                We stand as the premier destination for individuals and
                enterprises alike, offering a trusted platform for the
                acquisition, exchange, and administration of cryptocurrencies.
              </p>
            </div>
            <div className="d-flex gap-3">
              <Link to="/exchange/btc-inr" className="action-btn">
                Start Trading
              </Link>
              <Link to="/About" className="action-btn outline">
                Know More
              </Link>
            </div>

            {/* <div class="row hero_stats">
              <div class="col-lg-3">
                <div class="hero_number">
                  <strong>$641</strong>
                  <br />
                </div>
                <div class="hero_text">Avg. MultiHodl input in USD</div>
              </div>
              <div class="col-lg-3">
                <div class="hero_number">72</div>
                <div class="hero_text">Avg. monthly deals per user</div>
              </div>
              <div class="col-lg-3">
                <div class="hero_number">$52</div>
                <div class="hero_text">Assets on platform</div>
              </div>
              <div class="col-lg-3">
                <div class="hero_number">$85</div>
                <div class="hero_text">Quarterly volume traded</div>
              </div>
            </div> */}

            <div className="mt-5">
              <h5 className="fw-bold">
                More than {coins1 && coins1.length} trading pairs
              </h5>
            </div>
            <div className="pairs_wrapper p-0">
              <div className="container">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                  autoPlaySpeed={1500}
                  keyBoardControl={true}
                  // customTransition="all .5"
                  // transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  //  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  // itemClass="carousel-item-padding-40-px"
                >
                  {market_child}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="main-content">
       
      </div> */}
    </div>
  );
};

export default HeroSection;
