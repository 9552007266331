import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";

import Header from "./Header";
import Footer from "./Footer";
import CalltoAction from "./CalltoAction";
import FooterNav from "./FooterNav";

export default function FastTrade() {
  const { wallet, paired_currency_price, coins } = useSelector(
    (state) => state.coinDBReducer
  );
  const [inrValue, setInrValue] = useState(0);

  useEffect(() => {
    // Convert the coins object values into an array
    const coinsArray = Object.values(coins);

    // Find the cryptocurrency with the symbol 'USDT'
    const usdtCrypto = coinsArray.find((crypto) => crypto.symbol === "USDT");

    if (usdtCrypto) {
      // Set the 'inrValue' state to the current price of 'USDT'
      setInrValue(usdtCrypto.current_price);
    }
  }, [coins]);

  return (
    <>
      <Header />
      <div className="page-content">
        <div className="banner-section">
          {/* <div className="banner_left"></div>
          <div className="banner_right"></div> */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 col-sm-6 col-md-6 mb-3">
                <div className="banner_left_inner">
                  <h2 className="bannertxt"> Buy Crypto in One Click! </h2>
                  <p className="mb-4">
                    Choose Your Currency, Choose Your Method, and Seize Your
                    Opportunities Instantly.
                  </p>

                  <div className="payement_method mt-5">
                    <h4 className="mb-3">
                      We support as many as 70+ payment methods.
                    </h4>
                    <img src="./img/master.svg" />
                    <img src="./img/visa.svg" /> <img src="./img/paypal.svg" />{" "}
                    <img src="./img/payement2.svg" />
                    <img src="./img/sepa.svg" />
                    <img src="./img/paymethod.svg" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-sm-6 col-md-6">
                <div class="buysell-card mt-2 mb-2">
                  <div class=" p-0">
                    <div
                      class="nav nav-pills nav-justified"
                      id="nav-tab"
                      role="tablist"
                    >
                      <div
                        class="nav-item nav-link active"
                        href="#buy-tab"
                        data-toggle="tab"
                      >
                        Buy
                      </div>
                      <div
                        class="nav-item nav-link"
                        href="#sell-tab"
                        data-toggle="tab"
                      >
                        Sell
                      </div>
                    </div>
                  </div>
                  <div class=" custom_scrollbar">
                    <div class="tab-content p-4">
                      <div class="tab-pane active" id="buy-tab">
                        <div>
                          <label className="small text-muted">
                            I Want to Spend
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              value=""
                              placeholder="Limit: 10 -5,000"
                            />
                            <span class="input-group-text">
                              <img
                                src="./img/USDT.png"
                                className="me-1"
                                width={20}
                              />
                              USDT
                            </span>
                          </div>
                        </div>

                        <div>
                          <label className="small text-muted">
                            I Will Recieve
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control "
                              value=""
                              placeholder="Limit: 9.52 - 4,761.9"
                            />
                            <span class="input-group-text">
                              {" "}
                              <span class="input-group-text">
                                <img
                                  src="./img/USD.png"
                                  className="me-1"
                                  width={20}
                                />
                                USD
                              </span>
                            </span>
                          </div>
                        </div>
                        <div>
                          <label className="small text-muted">Pay With</label>
                          <div
                            class="payement pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#paymentmodal"
                          >
                            <img src="./img/visa.svg" />{" "}
                            <img src="./img/master.svg" /> Visa/Master Card
                          </div>
                        </div>

                        <div class="d-grid mt-2">
                          <button class="btn btn-success btn-lg">
                            BUY BTC
                          </button>
                        </div>
                        <span className="small-info">
                          Reference Price: 1 USDT ≈{" "}
                          {inrValue && `${inrValue} INR`}
                        </span>
                      </div>

                      <div class="tab-pane" id="sell-tab">
                        <div>
                          <label className="small text-muted">
                            I Want to Spend
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              value=""
                              placeholder="Limit: 10 -5,000"
                            />
                            <span class="input-group-text">
                              <img
                                src="./img/USDT.png"
                                className="me-1"
                                width={20}
                              />
                              USDT
                            </span>
                          </div>
                        </div>

                        <div>
                          <label className="small text-muted">
                            I Will Recieve
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control "
                              value=""
                              placeholder="Limit: 9.52 - 4,761.9"
                            />
                            <span class="input-group-text">
                              {" "}
                              <span class="input-group-text">
                                <img
                                  src="./img/USD.png"
                                  className="me-1"
                                  width={20}
                                />
                                USD
                              </span>
                            </span>
                          </div>
                        </div>

                        <div>
                          <label className="small text-muted">Pay With</label>
                          <div
                            class="payement pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#paymentmodal"
                          >
                            <img src="./img/visa.svg" />{" "}
                            <img src="./img/master.svg" /> Visa/Master Card
                          </div>
                        </div>

                        <div class="d-grid mt-2">
                          <button class="btn btn-danger btn-lg">
                            SELL BTC
                          </button>
                        </div>
                        <span className="small-info">
                          Reference Price:1 USDT ≈ 1.05 USD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page_spacer spacer">
          <div className="container">
            <div className="para_heading text-center">
              Invite Your Friends and Start Earning Together!
            </div>
            <div className="row d-flex align-center justify-content-center mt-5">
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5">
                    <div class="text-center mb-3">
                      <div class="vtimeline_no active"> 1</div>
                      <div class="vtimeline_right"></div>
                    </div>

                    <h5 className="refer_heading">Register </h5>
                    <p className="text-lgray">
                      Create an account on our platform by providing your basic
                      informationor details and verifying your email address.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5">
                    <div class="text-center mb-3">
                      <div class="vtimeline_left"></div>
                      <div class="vtimeline_no "> 2</div>
                      <div class="vtimeline_right"></div>
                    </div>

                    <h5 className="refer_heading">Add Funds</h5>
                    <p className="text-lgray">
                      Deposit funds into your account using your preferred
                      payment method, such as bank transfer or credit/debit
                      card.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5">
                    <div class="text-center mb-3">
                      <div class="vtimeline_left"></div>
                      <div class="vtimeline_no"> 3</div>
                    </div>

                    <h5 className="refer_heading">Trade Instantly</h5>
                    <p className="text-lgray">
                      With funds in your account, trading cryptocurrencies is a
                      breeze. Just select your desired pair, enter the amount,
                      and execute your trade swiftly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer pt-0">
          <div className="container">
            <div className="para_heading">Frequently Asked Questions</div>
            <p className="text-left text-lgray">
              Frequently asked questions (FAQs) for instant trading
            </p>

            <div className="row mt-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is instant trading?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Instant trading refers to the ability to execute
                        cryptocurrency trades quickly and efficiently without
                        delay, allowing users to take advantage of market
                        opportunities in real-time.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does instant trading work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Instant trading works by providing users with a platform
                      where they can buy or sell cryptocurrencies instantly at
                      the current market price without waiting for orders to be
                      matched with other traders.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Is instant trading different from regular trading?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, instant trading differs from regular trading in that
                      it allows for immediate execution of trades at the
                      prevailing market price, whereas regular trading may
                      involve placing orders that are matched over time based on
                      specific criteria.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      What are the advantages of instant trading?
                    </button>
                  </h2>
                  <div
                    id="collapse4"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading5"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The main advantage of instant trading is the ability to
                      capitalize on sudden price movements or market
                      opportunities without delay. It also offers convenience
                      and flexibility, allowing users to trade at any time
                      without waiting for orders to be filled.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      Are there any limitations to instant trading?
                    </button>
                  </h2>
                  <div
                    id="collapse5"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      While instant trading offers speed and convenience, it may
                      have limitations in terms of liquidity and price slippage,
                      especially during periods of high market volatility.
                      Additionally, instant trading may incur higher fees
                      compared to regular trading.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse6"
                      aria-expanded="false"
                      aria-controls="collapse6"
                    >
                      How can I start instant trading on this platform?
                    </button>
                  </h2>
                  <div
                    id="collapse6"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      To start instant trading on our platform, simply sign up
                      for an account, deposit funds, and navigate to the trading
                      interface. From there, you can select the cryptocurrency
                      pair you want to trade, enter the amount, and execute your
                      trade instantly.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      Is instant trading secure?
                    </button>
                  </h2>
                  <div
                    id="collapse5"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, we prioritize the security of our users' funds and
                      transactions. Our platform employs advanced security
                      measures such as encryption, two-factor authentication
                      (2FA), and cold storage to ensure the safety of your
                      assets during instant trading.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalltoAction />
      <FooterNav />

      <Footer />
    </>
  );
}
