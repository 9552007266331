export default class SocketClient {
  constructor(option) {
    this.baseUrl = 'wss://stream.binance.com:9443/ws'
    this.tvIntervals = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '480': '8h',
      '720': '12h',
      'D': '1d',
      '1D': '1d',
      '3D': '3d',
      'W': '1w',
      '1W': '1w',
      'M': '1M',
      '1M': '1M',
    };
    this.coin = option?.coin.join("").toLowerCase();
    this.price = option?.coin[1].toUpperCase() == "INR"?option?.price : 1;
    this.dispatch = option?.dispatch;
    this.trade_history = option?.trade_history
    this.streams = {}; // e.g: {'BTCUSDT': { paramStr: '', data:{}, listener:  } }
    this._createSocket();
  }

  _createSocket() {
    this._ws = new WebSocket(this.baseUrl)
    this._ws.onopen = (e) => {
      console.info(`Binance WS Open`)
      localStorage.setItem("wsStatus", 1)
    }

    this._ws.onclose = () => {
      console.warn('Binance WS Closed')
      localStorage.setItem("wsStatus", 0)
    }

    this._ws.onerror = (err) => {
      console.warn('WS Error', err)
      localStorage.setItem("wsStatus", 0)
    }

    this._ws.onmessage = (msg) => {
      if (!msg?.data) return
      let sData = JSON.parse(msg.data)
      try {
        if (sData && sData.k) {
          let { s, E } = sData
          let { o, h, l, v, c, T, t } = sData.k
          // Update data
          let lastSocketData = {
            time: t,
            close: parseFloat(c)*this?.price,
            open: parseFloat(o)*this?.price,
            high: parseFloat(h)*this?.price,
            low: parseFloat(l)*this?.price,
            volume: parseFloat(v)*this?.price,
            closeTime: T,
            openTime: t,
          }
          if (Object.keys(this.streams).length) {
            let res = {
              currency_type: this.option?.coin[0].toLowerCase(),
              compare_currency: this.option?.coin[1].toLowerCase(),
              raw_price: lastSocketData.close,
              volume: lastSocketData.volume,
              timestamp: lastSocketData.time,
            };
            
            // If the array exists, push the new data into it
            if (this.trade_history[this.coin]) {
              this.trade_history[this.coin].push(res);
            
              // If the length exceeds 20, remove the oldest item
              if (this.trade_history[this.coin].length > 22) {
                this.trade_history[this.coin].shift();
              }
            } else {
              // If the array doesn't exist yet, create it and add the new data as the first element
              this.trade_history[this.coin] = [res];
            }
            
            
            this.dispatch({ type: "SET_TRADE_HISTORY", data: this.trade_history, trade_loading: false });
            this.streams[s].data = lastSocketData
            this.streams[s].listener(lastSocketData)
          }
        }
      }
      catch (e) {
        console.error(e)
      }

    }
  }

  subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastDailyBar) {
    try {
      let paramStr = `${symbolInfo.name.toLowerCase()}@kline_${this.tvIntervals[resolution]}`
      const obj = {
        method: "SUBSCRIBE",
        params: [
          paramStr
        ],
        id: 1
      }
      if (this._ws.readyState === 1) {
        this._ws.send(JSON.stringify(obj))
        this.streams[symbolInfo.name] = {  //register multiple streams in streams object
          paramStr,
          listener: onRealtimeCallback
        }
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  unsubscribeFromStream(subscriberUID) {
    try {
      let id = subscriberUID.split("_")[0]
      const obj = {
        method: "UNSUBSCRIBE",
        params: [
          this.streams[id].paramStr
        ],
        id: 1
      }
      delete this.streams[id]
      if (this._ws.readyState === 1) {
        this._ws.send(JSON.stringify(obj))
      }
    }
    catch (e) {
      console.error(e)
    }
  }
}
