import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { N_getTradeData } from './redux/helpers/api_functions_new';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data }) => {
  const labels = data.map(item => item.month);
  const buyData = data.map(item => item.buy);
  const sellData = data.map(item => item.sell);

  const chartData = {
    labels,
    datasets: [
     
      {
        label: 'Buy (INR)',
        data: buyData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        barThickness: 40,  // Minimize the bar width
      },
      {
        label: 'Sell (INR)',
        data: sellData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        barThickness: 40,  // Minimize the bar width
      },
      
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Buy and Sell Data',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

const MyPNL = ({ totalDeposit, totalWithdraw, availableBalance, total }) => {
  const { user } = useSelector(
    (state) => state.AuthReducer
  );
  const [data, setData] = useState([]);
  useEffect(()=>{
    N_getTradeData(user?.params ? user.params.token : user.token)
    .then((res)=>{
  // Combine buy and sell arrays
  let combined_buy = [...res.buy_data, ...res.futurebuy_data, ...res.p2pbuy_data];
  let combined_sell = [...res.sell_data, ...res.futuresell_data, ...res.p2psell_data];
  
  // Create an object to store summed totals by month for buy
  let result_buy = {};
  
  // Calculate totals for each month for buy
  combined_buy.forEach(item => {
      let key = item.month;
      if (!result_buy[key]) {
          result_buy[key] = {
              "total": 0,
              "month": item.month
          };
      }
      result_buy[key].total += item.total;
  });
  
  // Convert result_buy object back to array format
  let finalResult_buy = Object.values(result_buy);
  
  // Create an object to store summed totals by month for sell
  let result_sell = {};
  
  // Calculate totals for each month for sell
  combined_sell.forEach(item => {
      let key = item.month;
      if (!result_sell[key]) {
          result_sell[key] = {
              "total": 0,
              "month": item.month
          };
      }
      result_sell[key].total += item.total;
  });
  
  // Convert result_sell object back to array format
  let finalResult_sell = Object.values(result_sell);
  // Define a function to get month name from month number
  function getMonthName(month) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[month - 1];
  }
  
  let new_data = [];
  // Iterate through both buy and sell arrays to collect data
  let months = new Set([...finalResult_buy.map(item => item.month), ...finalResult_sell.map(item => item.month)]);
  months.forEach(month => {
      let buyItem = finalResult_buy.find(item => item.month === month);
      let sellItem = finalResult_sell.find(item => item.month === month);
      
      new_data.push({
          month: getMonthName(month),
          buy: buyItem ? buyItem.total : 0,
          sell: sellItem ? sellItem.total : 0
      });
  });
  setData(new_data);
    })
  }, []);
  

  

  return (
    <div className="account_page">
      <div className="page-content-title">
        <h3 className="text-center">My PNL</h3>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="d-flex flex-wrap justify-content-center">
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Deposit</span>
                  <span>≈ {totalDeposit} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Withdrawal</span>
                  <span>≈ {totalWithdraw} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Available Balance</span>
                  <span>≈ {availableBalance} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>PNL</span>
                  <span className={total > 0 ? "text-success" : "text-danger"}>
                    ≈ {total > 0 ? "+ " : ""}{total} INR
                  </span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <BarChart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPNL;
