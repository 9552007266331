// WhyUs.js

import React from "react";
// import "./WhyUs.css"; // Import CSS file for styling
import { MdSecurity } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { FaChartLine } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { GrCompliance } from "react-icons/gr";
import { TbBulb } from "react-icons/tb";
import { LiaMoneyBillSolid } from "react-icons/lia";
import { RiShieldCheckLine } from "react-icons/ri";
import { Link } from "react-router-dom/cjs/react-router-dom";
const WhyUs = () => {
  return (
    <div className="huge-container">
      <div className="container">
        <div className="row  align-items-center">
          <div className="col-lg-4 col-12">
            <div className="feature-text pe-5">
              <h3>Discover Your Trading Edge: Why Us?</h3>
              <p>
                Benefit from our secure platform, diverse asset selection,
                user-friendly interface, competitive fees, and exceptional
                customer support. Join us today and experience the difference
              </p>
            </div>
            <div className="col-lg-12">
              <Link to="/About" className="action-btn outline me-3 mb-3">
               Know More 
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <div className="row">
              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <MdSecurity />
                    </div>
                  </div>
                  <div>
                    <div>
                      <h4>Security and Trust</h4>
                      <p>
                        Trade confidently with our top-tier security:
                        multi-factor authentication, cold storage, and regular
                        audits safeguard your funds.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <LiaMoneyBillSolid  />
                    </div>
                  </div>

                  <h4>Low Fees &amp; Competitive Rates</h4>
                  <p>
                    Benefit from low trading fees, ensuring maximum returns on
                    your investments
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <FaChartLine />
                    </div>
                  </div>
                  <div>
                    <h4>Educational Resources and Market Insights</h4>
                    <p>
                      Access a wealth of educational resources, including
                      tutorials, guides, and market analysis
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <TbBulb />
                    </div>
                  </div>
                  <div>
                    <h4>Tailored Approach</h4>
                    <p>
                      We provide a tailored approach to help you discover and
                      develop a trading edge that aligns with your individual
                      needs and preferences.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <RiShieldCheckLine  />
                    </div>
                  </div>
                  <div>
                    <div>
                      <h4>Regulatory Compliance and Trustworthiness</h4>
                      <p>
                        Compliance and security are our top priorities, ensuring
                        peace of mind for all users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <div className="custom_card">
                  <div className="logo">
                    <div class="featured_icon">
                      <BiSupport />
                    </div>
                  </div>
                  <div>
                    <h4>Exceptional Customer Support</h4>
                    <p>
                      24/7 to assist you with trading, deposits, or withdrawals.
                      Reach out anytime for personalized support
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-8">
          <div className="row spacer">
         
          </div>
          </div> */}
        </div>
      </div>

      {/* <div className="main-content">
       
      </div> */}
    </div>
  );
};

export default WhyUs;
