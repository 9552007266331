import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { GiWallet } from "react-icons/gi";
import "./p2p.css";
import {
  N_get_wallet,
  N_createp2pSellOrder
} from "../redux/helpers/api_functions_new";
export default function P2PBuyNSellNew(props) {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const coins1 = Object.values(coins);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(props.activeTab || 0);
  const [atPrice, setATPrice] = React.useState(0);
  const [wallet_details, setWalletDetails] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [sellbalance, setsellbalance] = React.useState(0);
  const [stopLimit, setStoplimit] = React.useState(0);
  const coin = ["USDT","INR"];
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const { wallet, paired_curency_price } = useSelector(
    (state) => state.coinDBReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);

  useEffect(() => {
    if (props.coin) {
      let coinData = coins1.find((item) => item.symbol == props.coin);
      setATPrice(coinData.current_price);
    }
  }, [props.coin]);

  function getCurrentBuySellTokenBalance() {
    N_get_wallet(user?.params ? user.params.token : user.token)
      .then((d) => {
        if (d.status === 200) {
          let final_data = Object.values(d.params.wallets);
          setWalletDetails(final_data);
        } else {
          console.log(d);
        }
      })
      .catch((e) => {
        console.log("er", e);
      });
  }

  useEffect(() => {
    getCurrentBuySellTokenBalance();
  }, []);

  useEffect(() => {
    let walletBalance = wallet_details.find(
      (item) => item.symbol == props.coin
    );
    let bal = walletBalance ? walletBalance.p2p_balance : 0;
    setsellbalance(bal);
  }, [props.coin, wallet_details]);

  function N_p2pSellorder(atPrice, amount, c, cp) {
    setLoading(true);
    // console.log("buy user: ", user);
    N_createp2pSellOrder(
      atPrice,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token
    )
      .then((d) => {
        if (d.status == 200) {
          NotificationManager.success(d.message);
          getCurrentBuySellTokenBalance();
        } else if (d.status == 400) {
          getCurrentBuySellTokenBalance();
          NotificationManager.success(d.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <div className="row p2p-works-container">
        <div className="tab-content">
          <div className="" id="p2p">
            {/* <div className="offset-7 col-5 py-3">
              <select className="custom-select inpdesign buy-sell-form-bg buy-sell-theme">
                <option value="0" selected>
                  Limit
                </option>
                <option value="1">Stop Limit</option>
              </select>
            </div> */}
            <div className="col-lg-5 m-auto py-3">
              {stopLimit === 1 ? (
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <span className="input-group-text itdesign buy-sell-form-bg buy-sell-theme">
                     Stop Price
                    </span>
                  </span>
                  <input type="number" className="form-control" value="0" />
                  <span className="input-group-text"> INR</span>
                </div>
              ) : null}
              <div className="input-group mb-3">
                <span className="input-group-text">Price</span>
                <input
                  type="number"
                  className="form-control"
                  value={atPrice}
                  onChange={(e) => {
                    setATPrice(
                      e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1")
                    );
                    setTotal(
                      e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1") * amount
                    );
                  }}
                />
                <span className="input-group-text">
                  {" "}
                  {coin[1]?.toUpperCase()}
                </span>
                <span className="input-group-text">
                  <span className="border-0 bg-transparent high-price">
                    Highest Price
                  </span>
                </span>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Amount</span>

                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setTotal(e.target.value * atPrice);
                  }}
                />
                <span className="input-group-text">{props.coin}</span>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Total Amount</span>

                <input
                  type="number"
                  className="form-control"
                  value={total}
                  onChange={(e) => {
                    setAmount(e.target.value / atPrice);
                    setTotal(e.target.value);
                  }}
                />
                <span className="input-group-text">
                  {coin[1]?.toUpperCase()}
                </span>
              </div>
              {isLoggedIn ? (
                <div className="">
                  <div className="col-12 text-end  mb-3">
                    <span className="mx-2" title="wallet">
                      <i class="fa fa-wallet"> </i>
                    </span>
                    {sellbalance + " " + props.coin}
                  </div>

                  <div className="col-12 d-flex justify-content-between">
                    <div
                      className="buy_sell_amount_picker"
                      onClick={() => {
                        setAmount(sellbalance ? sellbalance * 0.25 : 0);
                        setTotal(
                          sellbalance ? atPrice * sellbalance * 0.25 : 0
                        );
                      }}
                    >
                      25%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      onClick={() => {
                        setAmount(sellbalance ? sellbalance * 0.5 : 0);
                        setTotal(sellbalance ? atPrice * sellbalance * 0.5 : 0);
                      }}
                    >
                      50%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      onClick={() => {
                        setAmount(sellbalance ? sellbalance * 0.75 : 0);
                        setTotal(
                          sellbalance ? atPrice * sellbalance * 0.75 : 0
                        );
                      }}
                    >
                      75%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      onClick={() => {
                        setAmount(sellbalance ? sellbalance : 0);
                        setTotal(sellbalance ? atPrice * sellbalance : 0);
                      }}
                    >
                      100%
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="d-grid">
                <button
                  className="btn btn-danger my-2"
                  disabled={loading}
                  onClick={() => {
                    if (isLoggedIn) {
                      N_p2pSellorder(atPrice, amount, props.coin, "INR");
                    } else {
                      NotificationManager.error(
                        "First login then perform buy/sell"
                      );
                    }
                  }}
                >
                  {loading ? (
                    <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                  ) : null}
                  SELL {props.coin}
                </button>
              </div>
              {/* <div className="px-3 m-0">
                Fee: Maker fee: 0.2% | Taker fee: 0.2%
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
