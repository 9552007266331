import React, { useState } from "react";
import Header from "./HomeComp/Header";
import "./login.css";
import { isCpass, isPass } from "./redux/helpers/form-validator.functions";
import { password_update, user_logout } from "./redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { N_updatePassword } from "./redux/helpers/api_functions_new";
import { RxEyeNone, RxEyeOpen } from "react-icons/rx";
import { checkPassword } from "./redux/helpers/helper_functions";
export default function Forget(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const { otp_send, user } = useSelector((state) => state.AuthReducer);
  if (!otp_send) props.history.push("/login");
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const hsPassword1 = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye2(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye2(true);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (isPass(password) && isCpass(confPassword)) {
      if(checkPassword(password)) {
        if(checkPassword(confPassword)) {
          setLoading(true);
          N_updatePassword(user?.params ? user.params.token : user.token, password, confPassword)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              dispatch(
                user_logout(() => {
                  props.history?.replace("/login");
                })
              );
            } else {
              setErrorMessage(res.message)
              setLoading(false);
            }
          })
          .catch((e) => {
            setErrorMessage(e.message)
            setLoading(false);
          });
        } else {
          document.getElementById("cpass").focus()
        }
      } else {
        document.getElementById("pass").focus()
      }
      
    }
  };
  return (
    <>
      <Header {...props} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <div class="m-auto login_window signupform cstm_form">               
                            
                <form
                  className="signupform"
                  method="post"
                  id="signupform"
                  onSubmit={onSubmit}
                >
                  <div>
                  <h3 className="mb-3">Update Password</h3>    
                  </div>
                  <div className="error_div mb-2">
                      <span className="text-danger mb-0 fs-14" id="msg">
                        {errorMessage}
                      </span>
                    </div>
                  <div className={`form-group mb-4`}>
                    <label htmlFor="user_password">Password</label>
                    <div className="input-group">
                    <input
                      type="password"
                      className="form-control"
                      name="user_password"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(isPass(e.target.value))}
                      id="pass"
                      // placeholder="password"
                    />
                    <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword("pass");
                                  }}
                                >
                                  {eye ? <RxEyeOpen /> : <RxEyeNone />}
                                </a>
                              </span>
                              </div>
                    <small className="text-danger" id="passerr"></small>
                  </div>
                  <div className={`form-group mb-4`}>
                    <label htmlFor="user_cpassword">Confirm Password</label>
                    <div className="input-group">
                    <input
                      type="password"
                      name="user_cpassword"
                      className="form-control"
                      id="cpass"
                      required=""
                      value={confPassword}
                      onChange={(e) => setConfPassword(isCpass(e.target.value))}
                    />
                     <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword1("cpass");
                                  }}
                                >
                                  {eye2 ? <RxEyeOpen /> : <RxEyeNone />}
                                </a>
                              </span>
                              </div>
                    <small className="text-danger" id="cpasserr"></small>
                  </div>
                 <div className="d-grid">
                 <button type="submit" id="btn_submit" className="btn login_btn">
                    <i className="loading-icon fas fa-spinner fa-spin h"></i>
                    <span id="reg">Change</span>
                  </button>
                 </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
