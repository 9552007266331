import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { RxEyeNone, RxEyeOpen } from "react-icons/rx";
import { RiQrScan2Line } from "react-icons/ri";
import { FaApple, FaRegTimesCircle } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import OtpInput from "react-otp-input";
import "./login.css";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  isEmail,
  isNum,
  isPass,
} from "./redux/helpers/form-validator.functions";
import { user_Login, user_authentication } from "./redux/actions/authActions";
import { useGoogleLogin } from "@react-oauth/google";
// import { adminurl } from "../redux/helpers/api_functions_new";
import {
  N_getGoogleAuth,
  N_resendOTP,
  N_sendOTPMobileEmail,
  N_setActivityReport,
  N_verifyOTPNew,
  socialLogin,
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
import { checkEmail, checkPassword } from "./redux/helpers/helper_functions";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
export default function Login(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (isLoggedIn) props.history?.replace("/");
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [activetab, setActivetab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [otp, setotp] = useState();
  const [verifyotp, setverifyOtp] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [user, setUser] = useState([]);
  const [eye, seteye] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticator, setAuthenticator] = useState(0);
  const [checkotp, setCheckOtp] = useState(-1)
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    // setIsAuthenticating(true);
    // if (!email) {
    //   setErrorMessage("Please enter your email");
    //   setIsAuthenticating(false);
    //   return;
    // }
  
    // if (!password) {
    //   setErrorMessage("Please enter your password");
    //   setIsAuthenticating(false);
    //   return;
    // }
  
    if (isEmail(email) && isPass(password)) {
      if(checkEmail(email)) {
        if(checkPassword(password)) {
      N_getGoogleAuth(email, phone, password).then((data) => {
        if (data.status === 200 && data.authenticator_status === 0) {
          setLoading(true);
          dispatch(
            user_Login(
              email,
              phone,
              password,
              otp,
              (res) => {
                setLoading(false);
                dispatch({ type: AUTH_LOGIN, data: res });
                N_setActivityReport(res.params.token);
                props.history?.replace("/");
              },
              (res) => {
            setLoading(false)
            setErrorMessage(res.message);  
           }

            )
          );
        } else if (data.status === 300) {
          setIsOTPSent(true);
        } else {
          setAuthenticator(data.authenticator_status);
          setErrorMessage(data.message);
        }
      });
    } else {
      document.getElementById("pass").focus()
    }
  } else {
    document.getElementById("user_email").focus()
  }
    }
  
  };  

  const onSubmit = (newotp) => {
    if(authenticator==2||authenticator==1) {
      dispatch(
        user_Login(
          email,
          phone,
          password,
          newotp,
          (res) => {
            setCheckOtp(1)
            setTimeout(()=>{
              dispatch({ type: AUTH_LOGIN, data: res });
              N_setActivityReport(res.params.token);
              props.history?.replace("/");
            }, 1000)
           
          },
          (res) => {
            setCheckOtp(0)
            setErrorMessage(res.message);
          }
        )
      );
    } else {
      N_verifyOTPNew(email, phone, newotp).then((res) => {
        if (res.status == 200) {
          setCheckOtp(1);
          setTimeout(()=>{
            dispatch({ type: AUTH_LOGIN, data: res });
            N_setActivityReport(res.params.token);
          }, 1000)
        
        } else {
          setCheckOtp(0)
          setErrorMessage(res.message);
        }
        
      });
    }
   
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status==200) {
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          setErrorMessage(res.message);
        }
        
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <Header {...props} />

      <div class="container">
        <div className="page-content">
          <div class="row align-items-center">
            <div class="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12">
              <div class="d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    {isOTPSent ? (
                     <div class="login_window signupform">
                     <div className="page-content-title">
                       <h3 className="mb-lg-5">
                         Enter the 6-digit verification code we sent to {" "}
                         {email ? email : phone}
                       </h3>
                       <h6>For security, we need to verify it's you.</h6>
                     </div>
                     <form
                       className=""
                       method="post"
                       id="signupform"
                     >
                       {/* eroor message */}
                       <div className="my-3">
                         <p className="text-danger mb-0 fs-14" id="msg">                             
                           {errorMessage}
                         </p>
                       </div>

                       {/* Within the form */}
                       <div className="d-flex justify-content-center-- gap-3 align-items-center">
                       <OtpInput
                         value={verifyotp}
                         onChange={(otp) => {
                          if(otp.length==6) {
                            onSubmit(otp)
                          }
                          setverifyOtp(otp)
                        }}
                         numInputs={6}
                         // inputType="number"
                         renderSeparator={
                           <span
                             style={{
                               fontSize: "7px",
                               marginLeft: "5px",
                               marginRight: "5px",
                             }}
                           >
                             {" "}
                           </span>
                         }
                         isDisabled={loading}
                         renderInput={(inputProps, index) => (
                           <input {...inputProps} key={index} />
                         )}
                         inputStyle={{
                           width: "40px",
                           marginBottom: "10px",
                           height: "40px",
                           borderStyle: "solid",
                           borderWwidth: '1px',
                           borderColor: '#ccc',
                           borderRadius: '5px',                         
                           backgroundColor: "##F5F6F6",
                           outline: "none",
                         }}
                       />
                         {checkotp ==1?
                          <div className="mb-2">
                          <FaRegCircleCheck  className="text-green fs-2"/>
                          </div>: checkotp==0?
                          <div className="mb-2">
                            <FaRegTimesCircle  className="text-danger fs-2"/>
                          </div>: null}
                   </div>
                     
                       {/* <div className={`form-group`}>
                         <div className="input-group">
                           <input
                             type="number"
                             className="form-control"
                             name="user_otp"
                             id="user_otp"
                             required=""
                             value={otp}
                             onChange={(e) => setOtp(e.target.value)}
                             placeholder="Enter Verfication Code"
                           />
                           <span className="input-group-text">
                             <div className="">
                               <div className="">
                                 <button
                                   className="btn border-0 text-primary border-start rounded-0"
                                   onClick={(e) => {
                                     e.preventDefault();
                                     N_resendOTP(email, phone).then(
                                       (res) => {
                                         setErrorMessage(res.message);
                                       }
                                     );
                                   }}
                                 >
                                   Resend
                                 </button>
                               </div>
                             </div>
                           </span>
                         </div>
                       </div> */}
                       {/* <div className="d-grid mt-3">
                         <button
                           type="button"
                           id="btn_submit"
                           className="btn login_btn"
                           onClick={(e) => {
                             onSubmit(e);
                           }}
                         >
                           {loading ? (
                             <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                           ) : null}
                           <span id="reg">Verify</span>
                         </button>
                       </div> */}
                     </form>
                   </div>
                    ) : authenticator==2 || authenticator==1?(
                    <div class="login_window signupform">
                     <div className="page-content-title">
                       <h3 className="mb-lg-5">
                         Enter the 6-digit verification code we sent to {" "}
                         Google Authenticator App
                       </h3>
                       <h6>For security, we need to verify it's you.</h6>
                     </div>
                     <form
                       className=""
                       method="post"
                       id="signupform"
                     >
                       {/* eroor message */}
                       <div className="my-3">
                         <p className="text-danger mb-0 fs-14" id="msg">                             
                           {errorMessage}
                         </p>
                       </div>

                       {/* Within the form */}
                      <div className="d-flex justify-content-center-- gap-3 align-items-center">
                       <OtpInput
                       value={otp}
                       onChange={(otp1) => {
                        if(otp1.length==6) {
                          onSubmit(otp1);
                        }
                        setotp(otp1)
                       } }
                       numInputs={6}
                       // inputType="number"
                       renderSeparator={
                         <span
                           style={{
                             fontSize: "7px",
                             marginLeft: "5px",
                             marginRight: "5px",
                           }}
                         >
                           {" "}
                         </span>
                       }
                       isDisabled={loading}
                       renderInput={(inputProps, index) => (
                         <input {...inputProps} key={index} />
                       )}
                       inputStyle={{
                         width: "40px",
                         marginBottom: "10px",
                         height: "40px",
                         borderStyle: "solid",
                         borderWwidth: '1px',
                         borderColor: '#ccc',
                         borderRadius: '5px',                         
                         backgroundColor: "##F5F6F6",
                         outline: "none",
                       }}
                     />
                        {checkotp ==1?
                          <div className="mb-2">
                          <FaRegCircleCheck  className="text-green fs-2"/>
                          </div>: checkotp==0?
                          <div className="mb-2">
                            <FaRegTimesCircle  className="text-danger fs-2"/>
                          </div>:null}
                   </div>
                     
                       {/* <div className={`form-group`}>
                         <div className="input-group">
                           <input
                             type="number"
                             className="form-control"
                             name="user_otp"
                             id="user_otp"
                             required=""
                             value={otp}
                             onChange={(e) => setOtp(e.target.value)}
                             placeholder="Enter Verfication Code"
                           />
                           <span className="input-group-text">
                             <div className="">
                               <div className="">
                                 <button
                                   className="btn border-0 text-primary border-start rounded-0"
                                   onClick={(e) => {
                                     e.preventDefault();
                                     N_resendOTP(email, phone).then(
                                       (res) => {
                                         setErrorMessage(res.message);
                                       }
                                     );
                                   }}
                                 >
                                   Resend
                                 </button>
                               </div>
                             </div>
                           </span>
                         </div>
                       </div> */}
                       {/* <div className="d-grid mt-3">
                         <button
                           type="button"
                           id="btn_submit"
                           className="btn login_btn"
                           onClick={(e) => {
                             onSubmit(e);
                           }}
                         >
                           {loading ? (
                             <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                           ) : null}
                           <span id="reg">Verify</span>
                         </button>
                       </div> */}
                     </form>
                   </div>
                    ) : (
                      <div class="login_window signupform cstm_form">
                        <div className="page-content-title">
                          <h3 className="">Log In</h3>
                        </div>

                        <div className="d-grid">
                          <button
                            className="login-with-google-btn"
                            onClick={() => googleLogin()}
                          >
                            <div className="google_icon_bg">
                              <FcGoogle />
                            </div>{" "}
                            Sign in with Google
                          </button>

                          <div id="or">OR</div>
                        </div>

                        <form
                          className=""
                          method="post"
                          id="loginForm"
                          onSubmit={submit}
                          autoComplete="off"
                        >
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">                             
                              {errorMessage}
                            </p>
                          </div>
                          {activetab == 0 ? (
                            <div className="form-group mb-4">
                              <label htmlFor="user_email" className="">
                                Email
                              </label>
                              <input
                                type="email"
                                name="user_email"
                                className="form-control"
                                value={email}
                                autoComplete="off"
                                readOnly
                                onFocus={(e) => {
                                  e.target.removeAttribute("readOnly");
                                }}
                                id="user_email"
                                onChange={(e) => setEmail(isEmail(e.target.value))}
                                placeholder="eg: youremail@gmail.com"
                              />
                              <small>Error message</small>
                            </div>
                          ) : (
                            <div className="form-group mb-4">
                              <label>Phone Number</label>
                              <input
                                type="phone"
                                name="user_phone"
                                className="form-control"
                                autoComplete="off"
                                readOnly
                                onFocus={(e) => {
                                  e.target.removeAttribute("readOnly");
                                }}
                                value={phone}
                                id="user_phone"
                                onChange={(e) => setphone(e.target.value)}
                                placeholder="Phone Number"
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <label htmlFor="user_password" className="">
                              Password
                            </label>

                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                name="user_password"
                                id="pass"
                                value={password}
                                onChange={(e) => setPassword(isPass(e.target.value))}
                                placeholder="eg: yourpassword@123"
                              />
                              <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword("pass");
                                  }}
                                >
                                  {eye ? <RxEyeOpen /> : <RxEyeNone />}
                                </a>
                              </span>
                            </div>
                            <small className="text-danger" id="passerr"></small>
                          </div>

                          <div className="d-grid mb-4">
                            <button
                              type="submit"
                              id="login_btn"
                              className="btn login_btn"
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Log In</span>
                            </button>
                          </div>

                          <div className="text-left">
                            <Link
                              to="/forget"
                              className="text-lgray text-decoration-none fs-12"
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                        </form>

                        <div className="mt-3 fs-12">
                          <span className="fw-bold">Don't have account?</span>
                          <Link
                            className="text-decoration-none mx-2"
                            to="/create"
                          >
                            Sign up now
                          </Link>
                        </div>

                        <div className=" d-flex py-3">
                          <div>
                            <div className="fs-12">
                              Download App{" "}
                              <a href="#">                           
                                <FaApple className="fs-4 text-dark ms-2" />
                              </a>
                              <a href="#">
                                <FaGooglePlay className="fs-4 text-dark ms-3" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12">
              <h4 className="qrscan_heading"> Quick Log In - Scan using App</h4>
              <div className="d-flex justify-content-center align-items-center login_left_bg">
                <div class="qr-container">
                  <img src="/img/qrcode.png" />
                  <div class="marker"></div>
                </div>
              </div>
              <div className="py-3">
                <p className="fs-12 fw-bold">                  
                  <RiQrScan2Line className="text-primary fs-4" /> Scan QR code
                  with the latest Bitbse mobile app to Log In.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
