import React, { useState, useEffect } from "react";
import "./exside.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import {
  getElementsExistInAnotherArrayOnly,
  getRound,
  sortByChange,
  sortByPair,
  sortByPrice,
  sortByVol,
} from "../redux/helpers/helper_functions";
import { N_getWatchlist } from "../redux/helpers/api_functions_new";
// import { SET_PAIRED_CURRENCY_PRICE } from "../redux/constant";
import { BiBitcoin } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { RiMenuSearchLine } from "react-icons/ri";

export default function ExSide(props) {
  let {
    coins,
    user_fav_pairing,
    paired_curency_price,
    paired_curency,
    campare_currency,
  } = useSelector((state) => state.coinDBReducer);
  const { isLoggedIn, user } = useSelector(
    (state) => state.AuthReducer
  );
  const token = user?.params ? user.params.token : user.token;
  const coin = props.match.params.id.split("-");
  const [active, setActive] = useState(coin[1] ? coin[1] : campare_currency);
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [fav, setFav] = useState([]);
  const [watchlist, setWatchList] = useState([]);
  const [change, setChange] = useState(true);
  const [searchTxt, setSearch] = useState("");
  const [sort, setSort] = useState("");
  let coins_data = Object.values(coins);
  
  useEffect(() => {
    if(isLoggedIn) {
      N_getWatchlist(token)
      .then((res)=>{
        if(res.status==200) {
          setFav(res.favcoin)
        }
      })
    }
    
  }, [isLoggedIn]);

  useEffect(() => {
    let dd = coins_data.filter((d) => {
      if (d.symbol !== active.toUpperCase()) return d;
    });
    setData(dd);
  }, [coins_data.length, coins]);

  useEffect(() => {
    let dd;
    if (searchTxt != "") {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
    } else {
      dd = Object.values(coins_data).filter((d) => {
        if (d?.symbol != "INR" && d.symbol !== active.toUpperCase()) return d;
      });
    }
    setData(dd);
  }, [searchTxt, active]);

  useEffect(() => {
    if (searchTxt != "") {
      let dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
      let ddt = [];
      dd?.map((d) => {
        for (let i = 0; i < paired_curency.length; i++) {
          if (
            d?.symbol != "INR" &&
            d?.symbol !== paired_curency[i].currency_coin
          ) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = paired_curency[i].currency_coin;

            switch (paired_curency[i].currency_coin.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.usdt_price).toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number((d.current_price / paired_curency_price.USDT).toFixed(d.spread_usdt))
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number((d.btc_price).toFixed(d.spread_btc))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.BTC).toFixed(d.spread_btc))
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number((d.spread_vrx).toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.DTBX).toFixed(d.spread_vrx))
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
        }
      });
      setAllData(ddt);
    } else {
      let ddt = [];
      coins_data?.map((d) => {
        for (let i = 0; i < paired_curency.length; i++) {
          if (
            d?.symbol != "INR" &&
            d?.symbol !== paired_curency[i].currency_coin
          ) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = paired_curency[i].currency_coin;

            switch (paired_curency[i].currency_coin.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.usdt_price).toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number((d.current_price / paired_curency_price.USDT).toFixed(d.spread_usdt))
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number((d.btc_price).toFixed(d.spread_btc))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.BTC).toFixed(d.spread_btc))
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number((d.spread_vrx).toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.DTBX).toFixed(d.spread_vrx))
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
        }
      });
      setAllData(ddt);
    }
  }, [searchTxt, paired_curency]);

  useEffect(() => {
    let watchlistcoindata = getElementsExistInAnotherArrayOnly(Object.values(coins_data), fav);
    if (searchTxt != "") {
      let dd = watchlistcoindata.filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
      let ddt = [];
      dd?.map((d) => {
        for (let i = 0; i < fav.length; i++) {
          if(d?.symbol==fav[i]?.currency_type) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = fav[i].compare_currency;

            switch (pairing_currency?.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.usdt_price).toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number((d.current_price / paired_curency_price.USDT).toFixed(d.spread_usdt))
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number((d.btc_price).toFixed(d.spread_btc))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.BTC).toFixed(d.spread_btc))
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number((d.spread_vrx).toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.DTBX).toFixed(d.spread_vrx))
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
          
        }
      });
      setWatchList(ddt);
    } else {
      let ddt = [];
      watchlistcoindata?.map((d) => {
        for (let i = 0; i < fav.length; i++) {
          if(d?.symbol==fav[i]?.currency_type) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = fav[i]?.compare_currency;

            switch (pairing_currency?.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.usdt_price).toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number((d.current_price / paired_curency_price.USDT).toFixed(d.spread_usdt))
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number((d.btc_price).toFixed(d.spread_btc))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.BTC).toFixed(d.spread_btc))
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number((d.spread_vrx).toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number((d.current_price / paired_curency_price.DTBX).toFixed(d.spread_vrx))
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number((d.inr_price).toFixed(d.spread_inr))
                    : d.current_price
                    ? Number((d.current_price).toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
           
          
        }
      });
      setWatchList(ddt);
    }
  }, [searchTxt, fav]);


  return (
    <div className="pt-1 eth-market">
      <div className="col-lg-12 col-12 mt-3">
        <div className="input-group">
          <span className="input-group-text bg-transparent border-end-0">
            <div className="">
              <i className="fa fa-search text-gray"></i>
            </div>
          </span>
          <input
            type="text"
            className="form-control text-start mp-exide-form border-start-0"
            aria-label="Text input with checkbox"
            placeholder="Search "
            value={searchTxt}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
          />
        </div>
      </div>
      <div class="d-flex justify-content-start align-items-center my-3">
      <button
          key="0"
          id={1 * 2 + 5}
          class="filter_btn"
          role="button"
          className={` filter_btn ${active === "fav" ? "active" : ""} `}
          onClick={() => setActive("fav")}
        >
          <span>Watchlist</span>
        </button>
        <button
          key="0"
          id={0 * 2 + 5}
          role="button"
          className={` filter_btn ${active === "all" ? "active" : ""} `}
          onClick={() => setActive("all")}
        >
          <span>All</span>
        </button>
       
        {paired_curency && paired_curency.length > 0
          ? paired_curency.map((item, i) => (
              <button
                key={i + 2} // You should also add a unique key when mapping over an array
                id={(i + 2) * 2 + 5}
                className={` filter_btn ${
                  active === item.currency_coin.toLowerCase() ? "active" : ""
                } `}
                onClick={() => setActive(item.currency_coin.toLowerCase())}
                name="pln_currency"
              >
                {item.currency_coin}
              </button>
            ))
          : null}
      </div>

      <div className="my-3">
        {/* <div className="custom_pills">
          <div class="nav nav-pills nav-justified  " id="myTab" role="tablist">
         
            {paired_curency && paired_curency.length > 0
              ? paired_curency.map((item, i) => (
                  <button
                    key={i} // You should also add a unique key when mapping over an array
                    id={i * 2 + 5}
                    className={` nav-link text-primary ${
                      active === item.currency_coin.toLowerCase()
                        ? "active"
                        : ""
                    } `}
                    onClick={() => setActive(item.currency_coin.toLowerCase())}
                    name="pln_currency"
                  >
                    {item.currency_coin}
                  </button>
                ))
              : null}
          </div>
        </div> */}

        <div className="sidebar-overflow custom_scrollbar mt-2" id="style-3">
          <table
            id="coins_table"
            className="table custom_tbl table-layout tablesorter"
            //style={{ height: "auto" }}
          >
            <thead class="bg-transparent">
              <tr className="border-top-0 sticky_thead">
                <th
                  onClick={() => {
                    if (sort == "pair-asc") {
                      sortByPair(active=="all"?alldata:data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("pair-dsc");
                      });
                    } else {
                      sortByPair(active=="all"?alldata:data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("pair-asc");
                      });
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className=""> Coins</div>

                    <div className="mx-1">
                      <div className="position-relative">
                        <FaSortUp
                          className={`${
                            sort === "pair-asc" ? "text-primary" : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>

                      <div className="position-relative">
                        <FaSortDown
                          className={`${
                            sort === "pair-dsc" ? "text-primary" : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>
                      {/* {sort == "pair-asc" ? (
                        <div className="position-relative">
                          <FaSortUp className="sorting_btn" />
                        </div>
                      ) : null}
                      {sort == "pair-dsc" ? (
                        <div className="position-relative">
                          <FaSortDown className="sorting_btn" />
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </th>

                <th
                  onClick={() => {
                    if (sort == "vol-asc") {
                      sortByPrice(active=="all"?alldata:data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("vol-dsc");
                      });
                    } else {
                      sortByPrice(active=="all"?alldata:data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("vol-asc");
                      });
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className=""> Price</div>

                    <div className="mx-1">
                      <div className="position-relative">
                        <FaSortUp
                          className={`${
                            sort === "vol-asc" ? "text-primary" : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>
                      <div className="position-relative">
                        <FaSortDown
                          className={`${
                            sort === "vol-dsc" ? "text-primary" : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>
                    </div>
                  </div>
                </th>

                <th
                  className="text-end"
                  //   style={{ cursor: "pointer", outline: "none" }}
                  onClick={() => {
                    if (sort == "change-asc") {
                      sortByChange(active=="all"?alldata:data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("change-dsc");
                      });
                    } else {
                      sortByChange(active=="all"?alldata:data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("change-asc");
                      });
                    }
                  }}
                >
                  <div className="d-flex align-items-center justify-content-end">
                    <div className=""> 24H Change</div>

                    <div className="mx-1">
                      <div className="position-relative">
                        <FaSortUp
                          className={`${
                            sort === "change-asc"
                              ? "text-primary"
                              : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>

                      <div className="position-relative">
                        <FaSortDown
                          className={`${
                            sort === "change-dsc"
                              ? "text-primary"
                              : "text-lgray"
                          } sorting_btn`}
                        />
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {active == "all"
                ? alldata?.map((d, index) => (
                    <CoinRowAll
                      {...d}
                      key={index * 7}
                      closeDropdown={props.closeDropdown}
                      exchange={props.exchange}
                      token={token}
                      setFav = {setFav}
                      watchlist={watchlist}
                    />
                  ))
                : active != "fav"
                ? data?.map((d, index) => {
                    if (active.toUpperCase() != d.symbol.toUpperCase())
                      return (
                        <>
                          {active.toUpperCase() !== "BTC" &&
                          active.toUpperCase() !== "INR" &&
                          active.toUpperCase() !== "USDT" ? (
                            d["is_paired_custom_coin"] === 1 ? (
                              <CoinRow
                                {...d}
                                id={index * 5}
                                pairing_currency={active}
                                pc_price={paired_curency_price}
                                closeDropdown={props.closeDropdown}
                                exchange={props.exchange}
                                token={token}
                                setFav = {setFav}
                                watchlist={watchlist}
                              />
                            ) : null
                          ) : d[`is_paired_${active.toLowerCase()}`] === 1 ? (
                            <CoinRow
                              {...d}
                              id={index * 2 * 5}
                              pairing_currency={active}
                              pc_price={paired_curency_price}
                              closeDropdown={props.closeDropdown}
                              exchange={props.exchange}
                              token={token}
                              setFav = {setFav}
                              watchlist={watchlist}
                            />
                          ) : null}
                        </>
                      );
                    else return <></>;
                  })
                : watchlist?.map((d, index) => (
                    <CoinRowAll
                      {...d}
                      key={index * 7}
                      closeDropdown={props.closeDropdown}
                      exchange={props.exchange}
                      token={token}
                      setFav = {setFav}
                      watchlist={watchlist}
                    />
                    // console.log("fav_coin",)
                  ))}


                
            </tbody>
          </table>

          {/* <div className="text-center d-flex h-100 justify-content-center align-items-center">
           <div>
           <RiMenuSearchLine className="fs-1" />
           <p className="text-lgray">No record is found</p>
           </div>          
                  
          </div> */}
        </div>
      </div>
    </div>
  );
}

function CoinRow(props) {
  const history = useHistory();
  let price = 0;
  let coinStatus = false;
  let isSymbolAvailable = props.watchlist.some(item => item.symbol == props.symbol && item.pairing_currency==props.pairing_currency.toUpperCase());
  let isfav = isSymbolAvailable?true:false;
  switch (props.pairing_currency.toUpperCase()) {
    case "INR":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number((props.inr_price).toFixed(props.spread_inr))
          : props.current_price
          ? Number((props.current_price).toFixed(props.spread_inr))
          : 0.12
      );

      break;

    case "USDT":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number((props.usdt_price).toFixed(props.spread_usdt))
          : props.current_price
          ? Number((props.current_price / props.pc_price.USDT).toFixed(props.spread_usdt))
          : 0.03
      );

      break;
    case "BTC":
      price = coinStatus
        ? Number((props.btc_price).toFixed(props.spread_btc))
        : props.current_price
        ? Number((props.current_price / props.pc_price.BTC).toFixed(props.spread_btc))
        : 0.03;
      // console.log("check symbol_2: ", props.symbol, price);
      break;
    case "DTBX":
      price = coinStatus
        ? Number((props.spread_vrx).toFixed(props.spread_vrx))
        : props.current_price
        ? Number((props.current_price / props.pc_price.DTBX).toFixed(props.spread_vrx))
        : 0.03;
      break;
    default:
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number((props.inr_price).toFixed(props.spread_inr))
          : props.current_price
          ? Number((props.current_price).toFixed(props.spread_inr))
          : 0.12
      );
  }
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/${props.exchange}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/${props.exchange}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
        props.closeDropdown();
      }}
    >
      <td>
        <div className="d-flex align-items-center gap-2">
        <AiFillStar className={`${isfav?'favourite_icon':'unfavourite_icon'}`} onClick={(e)=>{
          e.stopPropagation();
          N_getWatchlist(props.token, props.symbol, props.pairing_currency?.toUpperCase())
          .then((res)=>{
            if(res.status==200) {
              props.setFav(res.favcoin)
            }
          })
        }}/>
          <img
            src={props.icon}
            alt={props.symbol}
            width="20"
            height="20"
          />
          <div className="">        
            <span>
              {/* {props.symbol}/{props.pairing_currency?.toUpperCase()} */}
              {props.symbol?.toUpperCase()}
            </span>
          </div>
        </div>
      </td>
      <td>
        <p className="no-margin text-fade mb-0">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {price}
            </>
          ) : (
            price
          )}
        </p>
      </td>
      <td className="text-end">
        <span className="fs-13">
          <span
            className={props.direction == "up" ? "text-success" : "text-danger"}
          >
            <i
              className={
                props.direction == "up" ? "fa fa-caret-up" : "fa fa-caret-down"
              }
            ></i>{" "}
            {getRound(props.price_change_percentage_1h)}%
          </span>
        </span>
      </td>
    </tr>
  );
}

function CoinRowAll(props) {
  const history = useHistory();
  let isSymbolAvailable = props.watchlist.some(item => item.symbol == props.symbol && item.pairing_currency==props.pairing_currency);
  let isfav = isSymbolAvailable?true:false;
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/${props.exchange}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/${props.exchange}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
        props.closeDropdown();
      }}
    >
      <td>
        <div className="d-flex align-items-center gap-2">
        <AiFillStar className={`${isfav?'favourite_icon':'unfavourite_icon'}`} onClick={(e)=>{
          e.stopPropagation();
          N_getWatchlist(props.token, props.symbol, props.pairing_currency?.toUpperCase())
          .then((res)=>{
            if(res.status==200) {
              props.setFav(res.favcoin)
            }
          })
        }}/>
          <img
            src={props.icon}
            alt={props.symbol}
            width="20"
            className="me-2"
            height="20"
          />
          <div className="">
            <span>
              {props.symbol}/{props.pairing_currency?.toUpperCase()}
              {/* {props.symbol?.toUpperCase()} */}
            </span>
          </div>
        </div>
      </td>
      <td>
        <p className="no-margin text-fade mb-0">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {props.price}
            </>
          ) : (
            props.price
          )}
        </p>
      </td>
      <td className="text-end">
        <span className="fs-13">
          <span
            className={props.direction == "up" ? "text-success" : "text-danger"}
          >
            <i
              className={
                props.direction == "up" ? "fa fa-caret-up" : "fa fa-caret-down"
              }
            ></i>{" "}
            {getRound(props.price_change_percentage_1h)}%
          </span>
        </span>
      </td>
    </tr>
  );
}
