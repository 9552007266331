import React, { useState } from "react";
import Header from "./HomeComp/Header";
import "./login.css";
import { isEmail } from "./redux/helpers/form-validator.functions";
import { user_forget } from "./redux/actions/authActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IoInformationCircleOutline } from "react-icons/io5";
import { N_forgetPassward } from "./redux/helpers/api_functions_new";
import { checkEmail } from "./redux/helpers/helper_functions";
export default function Forget(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
     
      if(isEmail(email)) {
        if(checkEmail(email)) {
          setLoading(true);
          N_forgetPassward(email)
          .then((res) => {
            if (res.status === 200) {
              dispatch({
                type: "F_OTP",
                data: { email: email },
              });
              props.history?.replace("/forget-password");
            } else {
              setErrorMessage(res.message);
            }
          })
          .catch((e) => {
            setErrorMessage(e.message);
          });
          setLoading(false);
        } else {
          document.getElementById("user_email").focus()
        }
       
      }
     
  };
  return (
    <>
      <Header {...props} />
     <div className="page-content">
     <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-6">           
            <div class="">
             
                  <div class="m-auto login_window-- signupform cstm_form">
                  
                      <h3 className="mb-5">Reset Password</h3>                     
                   
                      <div className="error_div mb-2">
                          <span className="text-danger mb-0 fs-14" id="msg">
                            {errorMessage}
                          </span>
                        </div>
                      <div className="form-group">
                        <label htmlFor="user_email">Email </label>
                        <input
                          type="email"
                          name="user_email"
                          value={email}
                          onChange={(e) => setEmail(isEmail(e.target.value))}
                          className="form-control"
                          id="user_email"
                          placeholder="Enter Email"
                        />
                        <small>Error message</small>
                      </div>
                      <p class="text-muted my-4">
                        <div><IoInformationCircleOutline className="text-warning fs-4" /> <span className="fw-bold">Important</span></div>
                      <span className="fs-12">Enter email, we'll send you new password in your inbox </span>
                      </p>
                      <div className="d-grid">
                        {" "}
                        <button
                          type="button"
                          id="btn_submit"
                          className="btn login_btn"
                          onClick={onSubmit}
                        >
                          <i className="loading-icon fas fa-spinner fa-spin me-2 h"></i>
                          <span id="reg">NEXT</span>
                        </button>
                      </div>
                      <div className="text-center my-4">
                        <Link className="text-primary fs-12 text-decoration-none" to="/login">
                          {" "}
                          Back to Login Page{" "}
                        </Link>
                      </div>
                    
               
            </div>
          </div>
        </div>
      </div>
     </div>
     </div>
     
     </>
  );
}
