
import "../chart.css";
import { TVChartContainer } from "../TVChartContainer";


export default function SpotChart(props) {
  const coin = props.match.params.id.split("-");
  return (
    <>
     
      <div class="card">
        <div class="card-body padding_0">
          <div id="candleCart1">
          <TVChartContainer {...props}
          tokenName={`${coin[0]}/${coin[1]}`}
        />
          </div>
        </div>
      </div>
    </>
  );
}
