import React from 'react'

export default function Blog() {
  return (
    <div className="container-fluid mt-5 blog_wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
          <div className="container">
            <h2 className="fw-bold text-center">Learing is Earning</h2>
            <h3 className="text-center fs-5">
              Discover the fundamentals of cryptocurrency if you're just
              starting out.
            </h3>
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="card shadow mb-4">
                  <div className="blog_img">
                    <img src="/img/blog1.jpg" className="img-fluid" />
                  </div>
                  <div className="card-content">
                    <h4>Basics of Crypto Exchanges</h4>
                    <p>
                      This section will delve into the foundational
                      knowledge needed to comprehend cryptocurrency
                      exchanges. It will cover topics such as what exchanges
                      are..
                    </p>
                    <div className="card-footer bg-transparent text-center">
                      <a href="#" className="btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="col-lg-4">
                <div className="card shadow mb-4">
                  <div className="blog_img">
                    <img src="/img/blog2.jpg" className="img-fluid" />
                  </div>
                  <div className="card-content">
                    <h4> Choosing the Right Exchange for You</h4>
                    <p>
                      This includes examining aspects like security
                      measures, fees, supported cryptocurrencies, trading
                      volume, user interface, and customer support.
                    </p>
                    <div className="card-footer bg-transparent text-center">
                      <a href="#" className="btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="col-lg-4">
                <div className="card shadow mb-4">
                  <div className="blog_img">
                    <img src="/img/blog3.jpg" className="img-fluid" />
                  </div>
                  <div className="card-content">
                    <h4>Tips for Safely Trading on Crypto Exchanges</h4>
                    <p>
                      It will cover topics such as setting up two-factor
                      authentication (2FA),avoiding phishing scams,
                      researching projects before investing, and
                      diversifying portfolios.
                    </p>
                    <div className="card-footer bg-transparent text-center">
                      <a href="#" className="btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
