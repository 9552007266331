import createSocketClient from "../../redux/helpers/socket";

export default class SocketClient {
  constructor() {
    this.tvIntervals = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '480': '8h',
      '720': '12h',
      'D': '1d',
      '1D': '1d',
      '3D': '3d',
      'W': '1w',
      '1W': '1w',
      'M': '1M',
      '1M': '1M',
    };
    this.streams = {}; // e.g: {'BTCUSDT': { paramStr: '', data:{}, listener:  } }
    this._createSocket();
  }

  _createSocket() {
    const moment = require("moment")
    this._ws = new createSocketClient("kujgwvfq-a-ghosttown-z-1fhhup0p6");

    this._ws.on("ohlc_updated", (res) => {
      let key = Object.keys(res)[0];
      if (Object.keys(res).length > 0) {
        let dt = res[key];
        if(Object.keys(this.streams)[0]==Object.keys(res)[0]) {
          let lastSocketData = {
            time: moment(Date.now()).unix() * 1000,
            close: dt.close,
            open: dt.open,
            high: dt.high,
            low: dt.low,
            volume: dt.volume
          }
          if (Object.keys(this.streams).length) {
            this.streams[key].data = lastSocketData
            this.streams[key].listener(lastSocketData)
          }
        }
       
       
      }
    });
    
  }

  subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastDailyBar) {
    try {
      let paramStr = `${symbolInfo.name.toLowerCase()}@kline_${this.tvIntervals[resolution]}`
        this.streams[symbolInfo.name.toLowerCase()] = {  //register multiple streams in streams object
          paramStr,
          listener: onRealtimeCallback
        }
      
    }
    catch (e) {
      console.error(e)
    }
  }

  unsubscribeFromStream(subscriberUID) {
    try {
      let id = subscriberUID.split("_")[0]
      delete this.streams[id]
    }
    catch (e) {
      console.error(e)
    }
  }
}
