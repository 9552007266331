import React from "react";
import Header from "../HomeComp/Header";

export default function PreLoader(props) {
  return (
    <div>
      <Header {...props} />

      <div className="container-fluid" style={{ marginTop: "3.8rem" }}>
        <div className="row placeholder-glow">
          <div className="col-lg-3 pe-1">
            <span class="placeholder placeholder_curency_market col-12 mb-1"></span>
          </div>
          <div className="col-lg-9 p-0">
            <span class="placeholder placeholder_curency_market col-12 mb-1"></span>
          </div>
        </div>
      <div className="row">
      <div className="col-12 col-md-7 col-lg-7 ps-1 pe-0 py-0">
          <div className=" placeholder-glow">
            <span class="placeholder chart_wrapper col-12 mb-1"></span>
          </div>
          <div className=" placeholder-glow">
            <span class="placeholder order_history col-12 mb-1"></span>
          </div>
        </div>

        <div className="col-12 col-md-5 col-lg-5">
          <div className="row">
            <div className="col-lg-6 ps-1 pe-1">
              <div className=" placeholder-glow">
                <span class="placeholder order_book col-12 mb-1"></span>
              </div>
            </div>
            <div className="col-lg-6 ps-0 ">
              <div className=" placeholder-glow">
                <span class="placeholder trade_history col-12 mb-1"></span>
              </div>
              <div className=" placeholder-glow">
                <span class="placeholder buy_sell col-12 mb-1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/*  <div id="preloader"> 
     <div className="preload-content">
          <h5
            className=""
            style={{
              position: "absolute",
              whiteSpace: "nowrap",
              top: "-46%",
              left: "-70%",
            }}
          >
            Security Checking.....
          </h5>
          <div id="dream-load"></div>
        </div>
      </div> */}
    </div>
  );
}
