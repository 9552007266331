import React, { useState } from "react";
import Header from "./HomeComp/Header";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { N_verifyForgetOTP } from "./redux/helpers/api_functions_new";
export default function FOTP(props) {
  const { otp_send, user } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  if (!otp_send) props.history.push("/login");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const handleProceedSubmit = (e) => {
    e.preventDefault();
    
    if(otp && otp?.length === 6) {
      setLoading(true);
      N_verifyForgetOTP(otp, user.email)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "F_OTP",
            data: { email: user.email, token: res.params.token },
          });
          props.history.push("/update-password");
          
        } else {
          setErrorMessage(res.message);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
      setLoading(false);
    } else {
      setErrorMessage(otp?.length !== 6?"Please enter 6 digit OTP":"OTP cannot be blank");
    }
   
  };
  return (
    <>
      <Header {...props} />
      <div class="container">
        <div className="page-content">
          <div class="row justify-content-center align-items-center">
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-9 col-sm-12">
              <div class="container">
                <div class="row mt-5">
                  <div className="">
                    <div class="login_window signupform-- text-center">
                      <h3>Verify your email</h3>
                      <div className="mt-4">
                        <p className="text-lgray fs-14 mb-0">
                          Enter the 6-digit verification code we sent to{" "}
                          {user.email}
                        </p>
                      </div>
                     
                      <form className="" method="post" id="signupform">
                        {/* eroor message */}
                        <div className="error_div mb-2">
                          <span className="text-danger mb-0 fs-14" id="msg">
                            {errorMessage}
                          </span>
                        </div>

                        {/* Within the form */}
                        <div className="d-flex justify-content-center">
                          <OtpInput
                            value={otp}
                            // onChange={(otp) => setOtp(otp)}
                            onChange={(otp) => {
                              if (/^\d+$/.test(otp) || otp === "") {
                                setOtp(otp);
                              }
                            }}
                            type="tel"
                            pattern="[0-9]*"
                            numInputs={6}
                            // inputType="number"
                            renderSeparator={
                              <span
                                style={{
                                  fontSize: "7px",
                                  marginLeft: "7px",
                                  marginRight: "7px",
                                }}
                              >
                                {" "}
                              </span>
                            }
                            isDisabled={loading}
                            renderInput={(inputProps, index) => (
                              <input {...inputProps} key={index} />
                            )}
                            inputStyle={{
                              width: "40px",
                              textAlign: "center",
                              marginBottom: "10px",
                              height: "40px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "#ddd",
                              color: "#444",
                              fontWeight: "bold",
                              fontSize: "1.5rem",
                              borderRadius: "5px",
                              backgroundColor: "#fff",
                              outline: "none",
                            }}
                          />
                          
                        </div>

                        <div className="mt-3">
                          <button
                            type="button"
                            id="btn_submit"
                            className="btn login_btn"
                            style={{ minWidth: "315px" }}
                            onClick={(e) => {
                              handleProceedSubmit(e);
                            }}
                          >
                            {loading ? (
                              <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                            ) : null}
                            <span id="reg">Verify</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
