import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import "./sidebar.css";
import { LuShieldCheck } from "react-icons/lu";
import { IoGiftOutline, IoSettingsOutline } from "react-icons/io5";
import { FiSmartphone } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  PiCirclesThreePlusFill,
  PiFolderSimpleUserDuotone,
  PiNumberCircleZeroFill,
} from "react-icons/pi";

import { RiShieldUserLine, RiStarSmileLine } from "react-icons/ri";
import { BsFillPatchExclamationFill, BsPatchCheckFill } from "react-icons/bs";
import { FcCheckmark } from "react-icons/fc";
import { GrUserAdd } from "react-icons/gr";
import { TfiAnnouncement } from "react-icons/tfi";
import premium from "./premium.json"
import Lottie from "react-lottie";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { useSelector } from "react-redux";
import { N_checkKYCStatus } from "./redux/helpers/api_functions_new";
import { getCoinRate, getRound } from "./redux/helpers/helper_functions";

export default function Dashboard() {
  const {user, profile} = useSelector((state)=>state.AuthReducer);
  let {
    wallet,
    paired_curency_price,
    wallet_loading,
    coins
  } = useSelector((state) => state.coinDBReducer);
  const [verified, setIsverified] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      // maxWidth: 220,
      fontSize: "12px",
      letterSpacing: 0.3,
      fontWeight: "normal",
      FontFamily: "IBM Plex Sans",
      // border: '1px solid #dadde9',
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#f5f5f9", // Change arrow color
    },
    [`& .MuiTooltip-arrow::before`]: {
      backgroundColor: "#f5f5f9", // Change arrow background color
    },
  }));
  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.token : user.token).then(
      (d) => {
        if (d.status === 200) {
          if (d?.params.status == 1) {
            setIsverified(1);
            setUserDetails(d.params);
          }
        }
      }
    );
  }, []);
  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol?.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }


  return (
    <>
      <Header />
      <div className="page-content">
        <main className="container">
          <div className="account_page">
          <div class="py-3">
              <div class="horizontal_display_with_justify">
                <div className="d-flex">
                  <div class="name_circle"> {profile?.name
                                  ? profile?.name
                                      ?.split("")
                                      .slice(0, 2)
                                      .join("")
                                      ?.toUpperCase()
                                  : profile?.email
                                      ?.split("")
                                      .slice(0, 2)
                                      .join("")
                                      ?.toUpperCase()}</div>
                  <div>
                    <div class="big_heading me-2 text-truncate mob_mw_150">
                      <h3> {profile?.email
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("") +
                                    "***@***" +
                                    profile?.email?.substr(
                                      profile?.email.lastIndexOf(".")
                                    )}</h3>
                    </div>
                    <div className="flex_row gap-3">
                      {/* <a class="profile-tag">
                        <RiUserStarLine className="text-primary me-1" />
                        VIP
                      </a> */}

                      <a class="profile-tag">
                        <HtmlTooltip
                          title="View VIP Benefits"
                          arrow
                          placement="top"
                        >
                          <div>
                            <PiNumberCircleZeroFill className="text-secondary me-1 fs-5" />
                            Non VIP
                          </div>
                        </HtmlTooltip>
                      </a>

                      <div class="profile-tag">
                        <HtmlTooltip
                          title="View My Identity Verificaion"
                          arrow
                          placement="top"
                        >
                          {!verified?
                          <div>
                            <BsFillPatchExclamationFill className="text-warning me-1" />
                            Not verified
                          </div>:
                          <div>
                            <BsPatchCheckFill className="text-green me-1" /> Verified
                          </div>}
                        </HtmlTooltip>
                      </div>

                      {/* <div class="profile-tag">                      
                        <BsPatchCheckFill className="text-green me-1" /> Verified
                      </div> */}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex align-items-center">
                    <Link to="/AccountSecurity">
                      <div class="circle_backdrop">
                        <HtmlTooltip
                          title="Acccount Security"
                          arrow
                          placement="top"
                        >
                          <div>
                            <LuShieldCheck />
                          </div>
                        </HtmlTooltip>
                      </div>
                    </Link>
                    <Link to="/AccountSecurity">
                      <div class="circle_backdrop">
                        <HtmlTooltip title="Setting" arrow placement="top">
                          <div>
                            <IoSettingsOutline />
                          </div>
                        </HtmlTooltip>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* when KYC is not verified */}
            {!verified?
            <>
            

            <div className="jambotron-outline mt-4">
              <div className="col-lg-12">
                <h3>Welcome Bitbse</h3>
                <p>
                  Complete the following steps to unlock up to 0.0000 USDT in
                  New User Rewards.
                </p>
              </div>

              <div class="new-user-kyc">
                <div class="flex_row gap-3">
                  <img src="/img/kyc-verifying.png" className="kyc_img" />
                  <h4>Complete Identity Verification to start trading</h4>
                </div>
                <div class="">
                  <Link to="/AccountVerification" className="btn btn-primary">
                    <span>Get Verified Now</span>
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </Link>
                </div>
              </div>

              <div className="py-3">
                <h6 className="new-user-guide-title m-0">
                  <FcCheckmark className="fs-3 me-1" />
                  The process takes around 1 minute
                </h6>
                <div className="d-flex gap-3 mb-3 px-4 ms-2 fs-16">
                  <div className="">
                    <PiFolderSimpleUserDuotone className="text-warning fs-4" />
                    ID photos
                  </div>
                  <div>
                    <RiStarSmileLine className="text-warning fs-4" /> Liveness
                    Check
                  </div>
                </div>
                <h6 className="new-user-guide-title">
                  <FcCheckmark className="fs-3 me-1" /> Unlock access to all
                  Bitbse products
                </h6>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="jambotron-outline ">
                  <h2 class="para_heading mb-0"> FAQs </h2>
                  <div className="col-lg-12 mt-2">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            Why is KYC important?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <ul className="list-unstyled faq_kyc_bullets">
                              <li>
                                <strong>1. Compliance:</strong> Financial
                                institutions and trading platforms are required
                                to comply with regulatory standards set by
                                government authorities. KYC regulations are
                                often mandated by laws such as the USA PATRIOT
                                Act in the United States or the Anti-Money
                                Laundering Directive in the European Union.
                              </li>

                              <li>
                                <strong>2. Risk Management:</strong> KYC
                                processes help in assessing and managing risks
                                associated with financial transactions. By
                                verifying the identities of customers,
                                businesses can better understand their clients'
                                risk profiles and detect suspicious activities.
                              </li>

                              <li>
                                <strong>3. Fraud Prevention:</strong>Verifying
                                the identity of users helps prevent identity
                                theft, account takeover, and other fraudulent
                                activities. It adds an additional layer of
                                security by ensuring that funds and assets are
                                not being used unlawfully.
                              </li>

                              <li>
                                <strong>4. Global Standards:</strong> Many
                                international financial institutions and trading
                                platforms operate across borders. Standardizing
                                KYC procedures helps ensure consistency and
                                compliance with global anti-money laundering
                                (AML) and counter-terrorism financing (CTF)
                                standards.
                              </li>

                              <li>
                                <strong>5. Customer Protection: </strong> KYC
                                verification also benefits customers by
                                protecting their accounts and assets from
                                unauthorized access and fraud. It contributes to
                                building trust between the trading platform and
                                its users.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            How long does the KYC verification process take?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            The duration of the KYC verification process can
                            vary depending on several factors, including the
                            specific financial institution or trading platform,
                            the volume of applications being processed, the
                            completeness of the submitted documents, and the
                            efficiency of the verification procedures.
                            <br /> <br />
                            Typically, the KYC verification process is completed
                            within about<strong> 15 minutes</strong>. However,
                            in certain cases where more detailed information
                            needs to be verified, the process may take up to 48
                            hours.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Can I amend my KYC's information (Proof of Identity
                            & Proof of Address) after verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            No, you can't amend your KYC information after
                            verification unless your legal information has
                            changed, i.e. Legal Name, Nationality.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            What are common reasons for verification failure?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <h6>Common Reasons for Verification Failure</h6>

                            <ul class="list-unstyled faq_kyc_bullets">
                              <li>
                                <h5 className="fs-14">
                                  Incomplete or Incorrect Information:
                                </h5>
                                <ul>
                                  <li>
                                    Missing or incomplete personal information
                                    such as name, date of birth, address, etc.
                                  </li>
                                  <li>
                                    Providing inaccurate or inconsistent
                                    information across different documents or
                                    forms.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14"> Expired Documents:</h5>
                                <ul>
                                  <li>
                                    Submitting identification documents (e.g.,
                                    passport, driver's license) that are expired
                                    or invalid.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">
                                  {" "}
                                  Poor Quality Documents:
                                </h5>
                                <ul>
                                  <li>
                                    Uploading documents that are blurry,
                                    unreadable, or altered, making it difficult
                                    to verify the information.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">
                                  {" "}
                                  Mismatched Information:{" "}
                                </h5>
                                <ul>
                                  <li>
                                    Discrepancies between the information
                                    provided in the KYC documents and the
                                    information provided during account
                                    registration or on other documents.
                                  </li>
                                  <li>
                                    Address mismatch between the provided proof
                                    of address and the user's registered
                                    address.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">
                                  {" "}
                                  Non-Compliant Documents:{" "}
                                </h5>
                                <ul>
                                  <li>
                                    Submitting documents that do not meet the
                                    platform's or regulatory standards for KYC
                                    verification.
                                  </li>
                                  <li>
                                    Using documents that are not accepted for
                                    verification purposes (e.g.,
                                    non-government-issued IDs).
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">
                                  {" "}
                                  Identity Verification Issues:{" "}
                                </h5>
                                <ul>
                                  <li>
                                    Cases where the submitted documents do not
                                    sufficiently prove the user's identity or
                                    are not linked to the individual applying
                                    for verification.
                                  </li>
                                  <li>
                                    Suspicion of identity theft or fraudulent
                                    activity based on the information provided.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">
                                  {" "}
                                  Regulatory Compliance Issues:
                                </h5>
                                <ul>
                                  <li>
                                    Failure to comply with specific regulatory
                                    requirements or restrictions set by the
                                    platform or governing authorities.
                                  </li>
                                  <li>
                                    Non-compliance with anti-money laundering
                                    (AML) and counter-terrorism financing (CTF)
                                    regulations.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">Technical Errors:</h5>
                                <ul>
                                  <li>
                                    System errors or glitches during the
                                    verification process that prevent accurate
                                    validation of documents or information.
                                  </li>
                                  <li>
                                    Issues with document upload functionality or
                                    communication errors during verification.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5 className="fs-14">Review Process: </h5>
                                <ul>
                                  <li>
                                    Cases where manual review is required due to
                                    flagged information or discrepancies,
                                    leading to a longer verification timeline or
                                    potential rejection.
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="jambotron mb-4">
                  <div class="d-flex justify-content-between align-items-center gap-3">
                    <div>
                      <h5 className="fs-14">Download App</h5>
                      <div class="text-lgray">
                        Get Bitbse App for the next level trading experience!
                      </div>
                    </div>
                    <div>
                      <img
                        src="/img/app-download.png"
                        width="100px"
                        alt="app download"
                      />
                    </div>
                  </div>
                  <div class="d-grid mt-3">
                    <Link class="btn btn-outline-secondary" to="/">
                      <FiSmartphone className="me-2" />
                      Get Bitbse App
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </>:
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-8">
                  <div className="box_wrapper mb-3">
                    <Link to="/rewardhub">
                      <div class="box_sqaure">
                        <div class="featured_icon">
                          <IoGiftOutline />
                        </div>

                        <div>
                          <div>
                            <h4 className="box-title">Rewards Hub</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/AffiliateHome">
                      <div class="box_sqaure">
                        <div class="featured_icon">
                          <GrUserAdd />
                        </div>
                        <div>
                          <div>
                            <h4 className="box-title">Referral</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Profile">
                      <div class="box_sqaure">
                        <div class="featured_icon">
                          <RiShieldUserLine />
                        </div>
                        <div>
                          <h4 className="box-title">My Account</h4>
                        </div>
                      </div>
                    </Link>
                    <Link to="/AllNotification">
                      <div class="box_sqaure">
                        <div class="featured_icon">
                          <TfiAnnouncement />
                        </div>
                        <div>
                          <div>
                            <h4 className="box-title">Announcement</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div class="jambotron-outline mt-3">
                    <div class="horizontal_display_with_justify">
                      <div class="h4">My Assets</div>
                      <div>
                        <Link to="/fundingMain" class="anchor_link">
                          Assets Details
                          <i class="fa-solid fa-arrow-right-long ms-1"></i>
                        </Link>
                      </div>
                    </div>
                    <div class="horizontal_display_with_justify mt-4">
                      <div>
                        <div class="text-lgray">Total Assets</div>
                        <h4>{total_usdt} USD</h4>
                        <p>
                          {total_usdt} $ <small class="text-lgray">≈ ₹ {total_inr}</small>
                        </p>
                      </div>
                      <div>
                        <Link class="btn btn-pink me-2" to="/AssetsDeposit">
                          Deposit
                        </Link>
                        <Link
                          class="btn btn-outline-primary"
                          to="/exchange/btc-inr"
                        >
                          Buy Crypto
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Trending Events</h4>
                    <div class="notice-items">
                      <div className="d-flex gap-3">
                        <div>
                          <img
                            src="/img/Options_Leaderboard_Web3.png"
                            className="trending-events-thumbnail"
                          />
                        </div>
                        <div className="w-75">
                          <div>
                            <h5 className="fs-14">
                              Stock Market Surges as Tech Giants Report Strong
                              Earnings
                            </h5>
                          </div>
                          <p className="text-lgray">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Optio voluptatem distinctio consectetur
                            quisquam ea.
                          </p>
                          <div className="text-muted mt-4 fs-12">
                            4 minutes ago
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="notice-items">
                      <div className="d-flex gap-3">
                        <div class="">
                          <img
                            src="/img/Signup_Web12.png"
                            className="trending-events-thumbnail"
                          />
                        </div>
                        <div className="w-75">
                          <div>
                            <h5 className="fs-14">
                              
                              Oil Prices Volatile as Geopolitical Tensions
                              Escalate in Middle East
                            </h5>
                          </div>
                          <p className="text-lgray">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Optio voluptatem distinctio consectetur
                            quisquam ea.
                          </p>
                          <div className="text-muted mt-4 fs-12">
                            24 minutes ago
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notice-items">
                      <div className="d-flex gap-3">
                        <div class="">
                          <img
                            src="/img/Spreads-web.jpeg"
                            className="trending-events-thumbnail"
                          />
                        </div>
                        <div className="w-75">
                          <div>
                            <h5 className="fs-14">
                              
                              Cryptocurrency Prices Rally Amid Increased
                              Investor Interest
                            </h5>
                          </div>
                          <p className="text-lgray">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Optio voluptatem distinctio consectetur
                            quisquam ea.
                          </p>
                          <div className="text-muted mt-4 fs-12">
                            30 minutes ago
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center my-3">
                      <a className="btn btn-outline-secondary" href="#">
                        Explore More Events
                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div class="jambotron mb-4">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: premium,
                      }}
                      width={120}
                    />

                    <h5 className="mt-3">Bitbse VIP Program</h5>
                    <div class="text-lgray">
                      Become a Bitbse VIP to unleash a World of exclusive perks
                      and trading benefits
                    </div>

                    <div className="white_bg_card mt-3 text-lgray">
                      <ul className="list-unstyled">
                        <li>
                          <PiCirclesThreePlusFill className="me-2 text-warning" />
                          VIP only products
                        </li>
                        <li>
                          <PiCirclesThreePlusFill className="me-2 text-warning" />
                          Upto 80% i Fee Discounts
                        </li>
                        <li>
                          <PiCirclesThreePlusFill className="me-2 text-warning" />
                          Earn with higher APY
                        </li>
                        <li>
                          <PiCirclesThreePlusFill className="me-2 text-warning" />
                          Elevated withdrawal limits
                        </li>
                      </ul>
                    </div>

                    <div class="d-grid mt-3">
                      <Link class="btn btn-outline-secondary" to="/">
                        How to become VIP
                        <i className="fa-solid fa-arrow-right-long ms-1"></i>
                      </Link>
                    </div>
                  </div>

                  <div class="jambotron mb-4">
                    <div class="d-flex justify-content-between align-items-center gap-3">
                      <div>
                        <h5 className="fs-14">Download App</h5>
                        <div class="text-lgray">
                          Get Bitbse App for the next level trading experience!
                        </div>
                      </div>
                      <div>
                        <img
                          src="/img/app-download.png"
                          width="100px"
                          alt="app download"
                        />
                      </div>
                    </div>
                    <div class="d-grid mt-3">
                      <Link class="btn btn-outline-secondary" to="/">
                        <FiSmartphone className="me-2" />
                        Get Bitbse App
                      </Link>
                    </div>
                  </div>

                  <div class="jambotron mb-4">
                    <h5 className="mb-4">
                      <HiOutlineSpeakerphone className="fs-3" /> Announcement
                    </h5>
                    <div class="noty-manager-list-item">
                      <div class="mb-3">
                        <h6>
                          Enjoy water sports and activities all year long in our
                          indoor and outdoor pools.
                        </h6>
                        <span className="fs-12 text-muted">14 minutes ago</span>
                      </div>
                      <div class="mb-3">
                        <h6>
                          Books, DVDs, audiobooks, e-books, video games,
                          language software - we've got it all!
                        </h6>
                        <span className="fs-12 text-muted">14 minutes ago</span>
                      </div>
                      <div class="mb-3">
                        <h6>
                          Join us for a game of cosmic bowling! And keep an eye
                          out for specials, which run often.
                        </h6>
                        <span className="fs-12 text-muted">14 minutes ago</span>
                      </div>
                      <div class="mb-3">
                        <h6>
                          The Far East Cafe, Atsugi's Galley, offers home-cooked
                          food to all active duty members.
                        </h6>
                        <span className="fs-12 text-muted">14 minutes ago</span>
                      </div>
                    </div>

                    <div class="mt-3">
                      <Link class="text-dgray" to="/AllNotification">
                        View More
                        <i className="fa-solid fa-arrow-right-long ms-1"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </main>
      </div>
    </>
  );
}
