import {
  checkEmail,
  checkPassword,
  checkOtp,
  checkName,
  checkPhone
} from "./helper_functions";
export function isEmail(email) {
  const obj = document.getElementById("user_email");
  if (email === "") {
    setErrorFor(obj, "Email can not be blank");
    return email;
  } else if (!checkEmail(email)) {
    setErrorFor(obj, "Not a valid email");
    return email;
  } else {
    setSuccessFor(obj);
    return email;
  }
}

export function isEmailInput(email) {
  const obj = document.getElementById("user_email");
  const obj1 = document.getElementById("passerr");
  if (email === "") {
    setErrorFor1(obj, obj1, "Email can not be blank");
    return email;
  } else if (!checkEmail(email)) {
    setErrorFor1(obj, obj1, "Not a valid email");
    return email;
  } else {
    setSuccessFor1(obj, obj1);
    return email;
  }
}

export function isPhone(phone) {
  const obj = document.getElementById("user_phone");
  const obj1 = document.getElementById("phone_err");
  if (phone === "") {
    setErrorFor1(obj, obj1, "Phone can not be blank");
    return phone;
  } else if (!checkPhone(phone)) {
    setErrorFor1(obj, obj1, "Not a valid phone");
    return phone;
  } else {
    setSuccessFor1(obj, obj1);
    return phone;
  }
}


export function isPass(password) {
  const obj = document.getElementById("pass");
  const obj1 = document.getElementById("passerr");
  if (password === "") {
    setErrorFor1(obj, obj1, "Password can not be blank");
    return password;
  } else if (!checkPassword(password)) {
    setErrorFor1(obj, obj1, "Password has to be between 8-30 characters, and contains at least one uppercase letter, one lowercase letter, one special character and a number");
    return password;
  } else {
    setSuccessFor1(obj, obj1);
    return password;
  }
}

export function isPassu(password) {
  const obj = document.getElementById("pass");
  const obj1 = document.getElementById("passerr1");
  if (password === "") {
    setErrorFor1(obj, obj1, "Password can not be blank");
    return password;
  } else if (!checkPassword(password)) {
    setErrorFor1(obj, obj1, "Password has to be between 8-30 characters, and contains at least one uppercase letter, one lowercase letter, one special character and a number");
    return password;
  } else {
    setSuccessFor1(obj, obj1);
    return password;
  }
}

export function isUPIID(upi) {
  const obj = document.getElementById("bank_upi");
  const obj1 = document.getElementById("passerr2");
  if (upi === "") {
    setErrorFor1(obj, obj1, "Password can not be blank");
    return upi;
  } else {
    setSuccessFor1(obj, obj1);
    return upi;
  }
}

export function isCpass(password2) {
  const obj = document.getElementById("cpass");
  const obj1 = document.getElementById("cpasserr");
  const pass = document.getElementById("pass").value;
  if (password2 === "") {
    setErrorFor1(obj, obj1, "Password can not be blank");
    return password2;
  } else if (pass !== password2) {
    setErrorFor1(obj, obj1, "Password does not match");
    return password2;
  } else {
    setSuccessFor1(obj, obj1);
    return password2;
  }
}

export function isOtp(otp) {
  const obj = document.getElementById("user_otp");
  if (otp === "") {
    setErrorFor(obj, "OTP can not be blank");
    return false;
  } else if (!checkOtp(otp)) {
    setErrorFor(obj, "Not a valid OTP");
    return false;
  } else if (otp?.length !== 6) {
    setErrorFor(obj, "Please enter 6 digit OTP");
    return false;
  } else {
    setSuccessFor(obj);
    return true;
  }
}

export function isAccount(accountNo) {
  const obj = document.getElementById("account_no");
  if (accountNo === "") {
    setErrorFor(obj, "Account Number can not be blank");
    return accountNo;
  } else {
    setSuccessFor(obj);
    return accountNo;
  }
}
export function isAccounttype(accountType) {
  const obj = document.getElementById("account_type");
  if (accountType === "") {
    setErrorFor(obj, "Please select an account type");
  } else {
    setSuccessFor(obj);
  }

  return accountType; // Return the validated account type
}

export function isBanktype(bankType) {
  const obj = document.getElementById("bank_type");
  if (bankType === "") {
    setErrorFor(obj, "Please select an Bank type");
  } else {
    setSuccessFor(obj);
  }

  return bankType; // Return the validated account type
}



export function isBankifsc(bankIFCS) {
  const obj = document.getElementById("ifsc_code");
  if (bankIFCS === "") {
    setErrorFor(obj, "IFSC Number can not be blank");
    return bankIFCS;
  } else {
    setSuccessFor(obj);
    return bankIFCS;
  }
}



export function checkValue(obj, message) {
  setErrorFor(obj, message);
}

export function isName(event) {
  const obj = document.getElementById(event.target.id);
  if (event.target.value.length < 3) {
    setErrorFor(obj, "name must be in 3 character");
  } else if (!checkName(event.target.value)) {
    setErrorFor(obj, "Not a valid name");
  } else {
    setSuccessFor(obj);
  }
}
export function isNumber(event) {
  const obj = document.getElementById(event.target.id);
  if (!checkOtp(event.target.value)) {
    setErrorFor(obj, "Not a valid pincode");
  } else {
    setSuccessFor(obj);
  }
}

export function isNum(num) {
  if (num === "") {
    return false;
  } else if (!checkOtp(num)) {
    return false;
  } else {
    return true;
  }
}
export function isDob(event) {
  const obj = document.getElementById(event.target.id);
  // if (!checkDob(event.target.value)) {
  // setErrorFor(obj, 'Not a valid date of birth');
  // } else {
  setSuccessFor(obj);
  // }
}

function setErrorFor(input, message) {
  const formControl = input?.parentElement;
  const small = formControl.querySelector("small");
  formControl.className = "form-group error";
  small.innerText = message;
}

function setSuccessFor(input) {
  const formControl = input?.parentElement;
  let parent = formControl.closest('.form-group');  // specific parent
  parent.className = "form-group success";
}

function setErrorFor1(input, eobj, message) {
  const formControl = input?.parentElement;
  // const small = formControl.querySelector("small");
  let parent = formControl.closest('.form-group');  // specific parent
  parent.className = "form-group error";
  eobj.innerText = message;
  eobj.style.visibility= "visible";
}

function setSuccessFor1(input, eobj) {
  const formControl = input?.parentElement;
  let parent = formControl.closest('.form-group');  // specific parent
  parent.className = "form-group success";
  if(eobj) {
    eobj.style.visibility= "hidden";
  }

}
