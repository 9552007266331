export const AUTH_LOGIN = "AUTH_LOGIN";
export const PROFILE = "PROFILE";
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_FORGET = "AUTH_FORGET";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_KYC = "AUTH_KYC";
export const SEND_OTP = "SEND_OTP";
export const SEND_AUTH = "SEND_AUTH";
export const F_OTP = "F_OTP";
export const GET_COIN_DATA = "GET_COIN_DATA";
export const CHANGE_ACTIVE_COIN = "CHANGE_ACTIVE_COIN";
export const SET_USER_BALANCE = "SET_USER_BALANCE";
export const SET_USER_FUTURE_BALANCE = "SET_USER_FUTURE_BALANCE";
export const SET_FUTURE_OPEN = "SET_FUTURE_OPEN";
export const SET_FUTURE_ORDER = "SET_FUTURE_ORDER";
export const SET_FUTURE_POSITION = "SET_FUTURE_POSITION";
export const SET_USER_ORDER_PENDING = "SET_USER_ORDER_PENDING";
export const SET_USER_ORDER_CLOSE = "SET_USER_ORDER_CLOSE";
export const SET_ORDER_BOOK = "SET_ORDER_BOOK";
export const SET_BUY_ORDER_BOOK = "SET_BUY_ORDER_BOOK";
export const SET_SELL_ORDER_BOOK = "SET_SELL_ORDER_BOOK";
export const SET_TRADE_HISTORY = "SET_TRADE_HISTORY";
export const SET_BUY_FUTURE_ORDER_BOOK = "SET_BUY_FUTURE_ORDER_BOOK";
export const SET_SELL_FUTURE_ORDER_BOOK = "SET_SELL_FUTURE_ORDER_BOOK";
export const SET_FUTURE_TRADE_HISTORY = "SET_FUTURE_TRADE_HISTORY";
export const SET_OHLC_DATA = "SET_OHLC_DATA";
export const SET_VHL_DATA = "SET_VHL_DATA";
export const SET_VHL_FUTURE_DATA = "SET_VHL_FUTURE_DATA";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const GET_CURRENCY_DATA = "GET_CURRENCY_DATA";
export const SET_USER_FAV_PAIRING = "SET_USER_FAV_PAIRING";
export const SET_USER_FAV_CURRENCY = "  SET_USER_FAV_CURRENCY";
export const SET_USER_FAV_CURRENCY_RATE = "SET_USER_FAV_CURRENCY_RATE";
export const GET_WEBSITE_DATA = "GET_WEBSITE_DATA";
export const SWITCH_THEME = "SWITCH_THEME";
export const SET_PAIRED_CURRENCY_PRICE = "SET_PAIRED_CURRENCY_PRICE";
export const SET_PAIRED_CURRENCY="SET_PAIRED_CURRENCY";
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS";
export const SELL_MARKET_PRICE = "SELL_MARKET_PRICE";
export const BUY_MARKET_PRICE = "BUY_MARKET_PRICE";
export const CALLBUYSELL = "CALLBUYSELL";
