import { useDispatch, useSelector } from "react-redux";
import { user_logout } from "../redux/actions/authActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MdArrowRightAlt,
  MdLogout,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { switchTheme } from "../redux/actions/coinDBAction";
import { LuArrowRight } from "react-icons/lu";
import { GoCopy, GoMoon } from "react-icons/go";
import { BsDownload, BsUpload } from "react-icons/bs";
import { IoMdArrowForward, IoMdCopy } from "react-icons/io";
import { HiOutlineSpeakerphone, HiUserCircle } from "react-icons/hi";
import { HiBars3 } from "react-icons/hi2";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

import {
  CiMenuFries,
  CiViewBoard,
  CiMoneyCheck1,
  CiMoneyBill,
  CiLaptop,
  CiUser,
} from "react-icons/ci";
import {
  IoLogOutOutline,
  IoNotificationsOutline,
  IoSunnyOutline,
} from "react-icons/io5";

import LogoImage from "../../public/main.png";
import { TbHistoryToggle, TbSettings2 } from "react-icons/tb";
import { adminurl } from "../redux/helpers/api_functions_new";
import { PiUserCircle } from "react-icons/pi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import { FaRegUserCircle, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  getRound,
  getCoinRate,
  sortByChange,
  sortByPair,
  sortByPrice,
  sortByVol,
  getMInutAgo,
} from "../redux/helpers/helper_functions";

import { N_RemoteStatusAll } from "../redux/helpers/api_functions_new";
// import { SET_PAIRED_CURRENCY_PRICE } from "../redux/constant";
import { BiBitcoin } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { RiMenuSearchLine } from "react-icons/ri";

export default function Header(props) {
  let {
    wallet,
    paired_curency_price,
    wallet_loading,
    user_fav_pairing,
    coins,
    paired_curency,
    campare_currency,
    notification_data
  } = useSelector((state) => state.coinDBReducer);
  const coin = props?.match?.params?.id?.split("-");
  const [active, setActive] = useState(
    coin && coin.length > 0 ? coin[1] : campare_currency
  );
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [change, setChange] = useState(true);
  const [searchTxt, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [not_data, setNotificationData] = useState("");
  let coins_data = coins && Object.values(coins);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenorder, setIsOpenorder] = useState(false);

  const toggleOrdermenu = () => {
    setIsOpenorder(!isOpenorder);
  };

  useEffect(() => {
    let dd = coins_data.filter((d) => {
      if (d.symbol !== active?.toUpperCase()) return d;
    });
    setData(dd);
  }, [coins_data.length, coins]);
  useEffect(() => {
    let dd;
    if (searchTxt != "") {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
    } else {
      dd = Object.values(coins_data).filter((d) => {
        if (d?.symbol != "INR" && d.symbol !== active?.toUpperCase()) return d;
      });
    }
    setData(dd);
  }, [searchTxt, active]);

  useEffect(() => {
    if (searchTxt != "") {
      let dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
      let ddt = [];
      dd?.map((d) => {
        for (let i = 0; i < paired_curency.length; i++) {
          if (
            d?.symbol != "INR" &&
            d?.symbol !== paired_curency[i].currency_coin
          ) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = paired_curency[i].currency_coin;

            switch (paired_curency[i].currency_coin?.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.inr_price.toFixed(d.spread_inr))
                    : d.current_price
                    ? Number(d.current_price.toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.usdt_price.toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number(
                        (d.current_price / paired_curency_price.USDT).toFixed(
                          d.spread_usdt
                        )
                      )
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number(d.btc_price.toFixed(d.spread_btc))
                  : d.current_price
                  ? Number(
                      (d.current_price / paired_curency_price.BTC).toFixed(
                        d.spread_btc
                      )
                    )
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number(d.spread_vrx.toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number(
                      (d.current_price / paired_curency_price.DTBX).toFixed(
                        d.spread_vrx
                      )
                    )
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.inr_price.toFixed(d.spread_inr))
                    : d.current_price
                    ? Number(d.current_price.toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
        }
      });
      setAllData(ddt);
    } else {
      let ddt = [];
      coins_data?.map((d) => {
        for (let i = 0; i < paired_curency.length; i++) {
          if (
            d?.symbol != "INR" &&
            d?.symbol !== paired_curency[i].currency_coin
          ) {
            let price = 0;
            let coinStatus = false;
            let pairing_currency = paired_curency[i].currency_coin;

            switch (paired_curency[i].currency_coin?.toUpperCase()) {
              case "INR":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.inr_price.toFixed(d.spread_inr))
                    : d.current_price
                    ? Number(d.current_price.toFixed(d.spread_inr))
                    : 0.12
                );

                break;

              case "USDT":
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.usdt_price.toFixed(d.spread_usdt))
                    : d.current_price
                    ? Number(
                        (d.current_price / paired_curency_price.USDT).toFixed(
                          d.spread_usdt
                        )
                      )
                    : 0.03
                );

                break;
              case "BTC":
                price = coinStatus
                  ? Number(d.btc_price.toFixed(d.spread_btc))
                  : d.current_price
                  ? Number(
                      (d.current_price / paired_curency_price.BTC).toFixed(
                        d.spread_btc
                      )
                    )
                  : 0.03;
                // console.log("check symbol_2: ", props.symbol, price);
                break;
              case "DTBX":
                price = coinStatus
                  ? Number(d.spread_vrx.toFixed(d.spread_vrx))
                  : d.current_price
                  ? Number(
                      (d.current_price / paired_curency_price.DTBX).toFixed(
                        d.spread_vrx
                      )
                    )
                  : 0.03;
                break;
              default:
                price = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 8,
                  maximumSignificantDigits: 8,
                }).format(
                  coinStatus
                    ? Number(d.inr_price.toFixed(d.spread_inr))
                    : d.current_price
                    ? Number(d.current_price.toFixed(d.spread_inr))
                    : 0.12
                );
            }
            let dd = { price, pairing_currency };
            ddt.push({ ...d, ...dd });
          }
        }
      });
      setAllData(ddt);
    }
  }, [searchTxt, paired_curency]);

  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLinkHover = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  // mobile user drawer
  const [isDrawerMobopen, setIsDrawerMobopen] = useState(false);

  const handleMobdrawerToggle = () => {
    setIsDrawerMobopen(!isDrawerMobopen);
  };

  const isTradeDropdownActive = () => {
    const paths = ["/exchange/", "/FastTrade", "/p2pOrder"]; // Add more paths if needed
    return paths.some((path) => location.pathname.includes(path));
  };

  const isAssetDropdownActive = () => {
    const paths = ["/fundingMain", "/MyBonus"]; // Add more paths if needed
    return paths.some((path) => location.pathname.includes(path));
  };
  
  const isOrderDropdownActive = () => {
    const paths = ["/SpotOrders"]; 
    return paths.some((path) => location.pathname.includes(path));
  };

  const dispatch = useDispatch();
  const { user, profile, switch_theme, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  // const { wallet, paired_curency_price, wallet_loading } = useSelector(
  //   (state) => state.coinDBReducer
  // );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);

  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    handleDrawerClose();
  }

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol?.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }

  async function swtchTheme() {
    if (switch_theme == "dark") {
      dispatch(switchTheme("light"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "light");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_light");
      body?.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-sun");
      icon?.classList.remove("fa-moon");
    } else {
      dispatch(switchTheme("dark"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "dark");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_dark");
      body?.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");

      icon?.classList.add("fa-moon");
      icon?.classList.remove("fa-sun");
    }
  }

  

  useEffect(()=>{
    if(isLoggedIn) {
      let dt = notification_data[profile?.email];
      setNotificationData(dt)
    }
  }, [isLoggedIn])

  return (
    <>
      <header>
        <nav
          className={`navbar navbar-expand-lg fixed-top header_bg py-0 w3_megamenu ${
            props?.location?.pathname?.split("/")?.includes("exchange")
              ? ""
              : "custom_shadow "
          }onhoverdrpdwn`}
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img
                src={`${adminurl}/images/logo.png`}
                alt="Logo"
                className=""                
                height="30"
              />
            </Link>

            <div className="d-block d-lg-none">
              <div className="d-flex gap-3 align-items-center">
                {isLoggedIn ? (
                  <>
                    {/* <div
                      data-bs-toggle="offcanvas"
                      href="#userprofileMobile"
                      role="button"
                      aria-controls="userprofileMobile"
                    >
                      <HiUserCircle className=" text-primary fs-1" />
                    </div> */}

                    {/* Mobile drawer button */}

                    <div
                      className="cursor_pointer"
                      onClick={handleMobdrawerToggle}
                    >
                      <div>
                        <HiUserCircle className=" text-primary fs-1" />
                      </div>
                    </div>

                  </>
                ) : null}

                <button
                  className="navbar-toggler p-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <HiBars3 size={30} />
                </button>
              </div>
            </div>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="navbarNav"
              aria-labelledby="navbarNavLabel"
            >
              <div className="offcanvas-header">
                <h5 class="offcanvas-title"></h5>
                <button
                  type="button"
                  className="btn-close bg-transparent"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                {/* Desktop menu start */}
                <div className="d-flex align-items-scenter justify-content-between pd_menu_mob hide_on_mobile w-100">
                  <ul className="navbar-nav custom_nav left_nav">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/About" ? "active" : ""
                        }`}
                        to="/About"
                      >
                        About
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/Markets" ? "active" : ""
                        }`}
                        to="/Markets"
                      >
                        Markets
                      </Link>
                    </li>
                    <li className="nav-item nested_menuwrapper">
                      <a
                        class={`nav-link dropdown-toggle ${
                          isTradeDropdownActive() ? "active" : ""
                        }`}
                        href="#"
                        data-toggle="dropdown"
                      >
                        Trade
                      </a>
                      <ul class="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to="/FastTrade" className="nav-link">
                            <div className="">
                              <div className="fs-14">Instant Trade</div>
                              <span className="text-lgray fs-12">
                                Buy Crypto in one click
                              </span>
                            </div>
                          </Link>
                        </li> */}

                        <li class="dropdown-submenu multilevel_menu">
                          <Link
                            to="/exchange/trx-inr"
                            class="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <div className="fs-14">Spot Trade</div>
                                <span className="text-lgray fs-12">
                                  Buy and sell on Spot
                                </span>
                              </div>
                              <MdOutlineKeyboardArrowRight className="fs-4" />
                            </div>
                          </Link>
                          <ul class="dropdown-menu">
                            <li>
                              <div class="d-flex justify-content-start align-items-center my-3 navbar_filter">
                                <button
                                  key="0"
                                  id={0 * 2 + 5}
                                  role="button"
                                  className={` filter_btn ${
                                    active === "all" ? "active" : ""
                                  } `}
                                  onClick={() => setActive("all")}
                                >
                                  <span>All</span>
                                </button>

                                {paired_curency && paired_curency.length > 0
                                  ? paired_curency.map((item, i) => (
                                      <button
                                        key={i + 2} // You should also add a unique key when mapping over an array
                                        id={(i + 2) * 2 + 5}
                                        className={` filter_btn ${
                                          active ===
                                          item.currency_coin.toLowerCase()
                                            ? "active"
                                            : ""
                                        } `}
                                        onClick={() =>
                                          setActive(
                                            item.currency_coin.toLowerCase()
                                          )
                                        }
                                        name="pln_currency"
                                      >
                                        {item.currency_coin}
                                      </button>
                                    ))
                                  : null}
                              </div>
                              <div class="my-3">
                                <div className="navbar_table_scroll header_coin_scrollbar">
                                  <table
                                    id="coins_table"
                                    class="table custom_tbl table-layout tablesorter fw-normal table-hover"
                                  >
                                    <tbody>
                                      {active == "all"
                                        ? alldata?.map((d, index) => (
                                            <CoinRowAll
                                              {...d}
                                              type="exchange"
                                              key={index * 7}
                                              closeDropdown={
                                                props.closeDropdown
                                              }
                                            />
                                          ))
                                        : active != "fav"
                                        ? data?.map((d, index) => {
                                            if (
                                              active?.toUpperCase() !=
                                              d.symbol?.toUpperCase()
                                            )
                                              return (
                                                <>
                                                  {active?.toUpperCase() !==
                                                    "BTC" &&
                                                  active?.toUpperCase() !==
                                                    "INR" &&
                                                  active?.toUpperCase() !==
                                                    "USDT" ? (
                                                    d[
                                                      "is_paired_custom_coin"
                                                    ] === 1 ? (
                                                      <CoinRow
                                                        {...d}
                                                        id={index * 5}
                                                        pairing_currency={
                                                          active
                                                        }
                                                        pc_price={
                                                          paired_curency_price
                                                        }
                                                        closeDropdown={
                                                          props.closeDropdown
                                                        }
                                                      />
                                                    ) : null
                                                  ) : d[
                                                      `is_paired_${active.toLowerCase()}`
                                                    ] === 1 ? (
                                                    <CoinRow
                                                      {...d}
                                                      id={index * 2 * 5}
                                                      type="exchange"
                                                      pairing_currency={active}
                                                      pc_price={
                                                        paired_curency_price
                                                      }
                                                      closeDropdown={
                                                        props.closeDropdown
                                                      }
                                                    />
                                                  ) : null}
                                                </>
                                              );
                                            else return <></>;
                                          })
                                        : user_fav_pairing?.map((d, index) => (
                                            <CoinRowAll
                                              {...d}
                                              type="exchange"
                                              key={index * 7}
                                              closeDropdown={
                                                props.closeDropdown
                                              }
                                            />
                                            // console.log("fav_coin",)
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li class="dropdown-submenu multilevel_menu">
                          <Link
                            to="/derivative/trx-inr"
                            class="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <div className="fs-14">Future Trade</div>
                                <span className="text-lgray fs-12">
                                  Buy and sell
                                </span>
                              </div>
                              <MdOutlineKeyboardArrowRight className="fs-4" />
                            </div>
                          </Link>
                          <ul class="dropdown-menu">
                            <li>
                              <div class="d-flex justify-content-start align-items-center my-3 navbar_filter">
                                <button
                                  key="0"
                                  id={0 * 2 + 5}
                                  role="button"
                                  className={` filter_btn ${
                                    active === "all" ? "active" : ""
                                  } `}
                                  onClick={() => setActive("all")}
                                >
                                  <span>All</span>
                                </button>

                                {paired_curency && paired_curency.length > 0
                                  ? paired_curency.map((item, i) => (
                                      <button
                                        key={i + 2} // You should also add a unique key when mapping over an array
                                        id={(i + 2) * 2 + 5}
                                        className={` filter_btn ${
                                          active ===
                                          item.currency_coin.toLowerCase()
                                            ? "active"
                                            : ""
                                        } `}
                                        onClick={() =>
                                          setActive(
                                            item.currency_coin.toLowerCase()
                                          )
                                        }
                                        name="pln_currency"
                                      >
                                        {item.currency_coin}
                                      </button>
                                    ))
                                  : null}
                              </div>
                              <div class="my-3">
                                <div className="navbar_table_scroll header_coin_scrollbar">
                                  <table
                                    id="coins_table"
                                    class="table custom_tbl table-layout tablesorter fw-normal table-hover"
                                  >
                                    <tbody>
                                      {active == "all"
                                        ? alldata?.map((d, index) => (
                                            <CoinRowAll
                                              {...d}
                                              type="derivative"
                                              key={index * 7}
                                              closeDropdown={
                                                props.closeDropdown
                                              }
                                            />
                                          ))
                                        : active != "fav"
                                        ? data?.map((d, index) => {
                                            if (
                                              active?.toUpperCase() !=
                                              d.symbol?.toUpperCase()
                                            )
                                              return (
                                                <>
                                                  {active?.toUpperCase() !==
                                                    "BTC" &&
                                                  active?.toUpperCase() !==
                                                    "INR" &&
                                                  active?.toUpperCase() !==
                                                    "USDT" ? (
                                                    d[
                                                      "is_paired_custom_coin"
                                                    ] === 1 ? (
                                                      <CoinRow
                                                        {...d}
                                                        id={index * 5}
                                                        pairing_currency={
                                                          active
                                                        }
                                                        pc_price={
                                                          paired_curency_price
                                                        }
                                                        closeDropdown={
                                                          props.closeDropdown
                                                        }
                                                      />
                                                    ) : null
                                                  ) : d[
                                                      `is_paired_${active.toLowerCase()}`
                                                    ] === 1 ? (
                                                    <CoinRow
                                                      {...d}
                                                      type="derivative"
                                                      id={index * 2 * 5}
                                                      pairing_currency={active}
                                                      pc_price={
                                                        paired_curency_price
                                                      }
                                                      closeDropdown={
                                                        props.closeDropdown
                                                      }
                                                    />
                                                  ) : null}
                                                </>
                                              );
                                            else return <></>;
                                          })
                                        : user_fav_pairing?.map((d, index) => (
                                            <CoinRowAll
                                              {...d}
                                              type="derivative"
                                              key={index * 7}
                                              closeDropdown={
                                                props.closeDropdown
                                              }
                                            />
                                            // console.log("fav_coin",)
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>

                        <li className="nav-item">
                          <Link to="/p2pOrder" className="nav-link">
                            <div className="">
                              <div className="fs-14">P2P Trade</div>
                              <span className="text-lgray fs-12">
                                Trade directly with peers, no hidden fees
                              </span>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/StakingPlans" ? "active" : ""
                        }`}
                        to="/StakingPlans"
                      >
                        Staking
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/AffiliateHome" ? "active" : ""
                        }`}
                        to="/AffiliateHome"
                      >
                        Referral
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/rewardhub" ? "active" : ""
                        }`}
                        to="/rewardhub"
                      >
                        Rewards
                      </Link>
                    </li>
                  </ul>

                  <ul className="navbar-nav align-items-lg-center gap-3 custom_nav right_nav">
                    {isLoggedIn ? (
                      <>
                        <li className="nav-item">
                          <Link className="button-23" to="/AssetsFiat">
                            Deposit
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            className="button-23"
                            to="/AssetsWithdrawFiat"
                          >
                            Withdrawal
                          </Link>
                        </li>
                        <li
                          className="nav-item dropdown custom_drpdwn"
                          onMouseOver={handleDrawerClose}
                        >
                          <a
                            class={`nav-link dropdown-toggle ${
                              isAssetDropdownActive() ? "active" : ""
                            }`}
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Assets
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-end--"
                            aria-labelledby="navbarDropdown"
                          >
                            <section className="header_asset_content">
                              <div class="header-card mb-4">
                                <h6 class="small text-lgray">
                                  Assets Overview
                                </h6>
                                <h5 className="mb-3">{total_usdt} USD</h5>
                                <div class="d-flex gap-4 my-2">
                                  <Link
                                    to="/AssetsDeposit"
                                    className="btn btn-primary"
                                  >
                                    <BsDownload /> Deposit
                                  </Link>
                                  <Link
                                    to="/AssetsWithdraw"
                                    className="btn btn-primary"
                                  >
                                    <BsUpload /> Withdrawal
                                  </Link>
                                </div>
                              </div>

                              <div className="">
                                <div class="asset-dropdown-header">Account</div>
                                <Link
                                  className="dropdown-item"
                                  to="/fundingMain"
                                >
                                  Spot Trading Wallet
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/p2pFundingMain"
                                >
                                  P2P Trading Wallet
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/futureFundingMain"
                                >
                                  Future Trading Wallet
                                </Link>
                              </div>
                              {/* <hr class="dropdown-divider" />

                              <div class="asset-dropdown-header">
                                Invested Products
                              </div>
                              <Link className="dropdown-item" to="/MyBonus">
                                Earn
                              </Link> */}
                            </section>
                          </div>
                        </li>

                        <li
                          className="nav-item dropdown custom_drpdwn"
                          onMouseEnter={handleDrawerClose}
                        >
                          <a
                            class={`nav-link dropdown-toggle ${
                              isOrderDropdownActive() ? "active" : ""
                            }`}
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Orders
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-end--"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link className="dropdown-item" to="/SpotOrders">
                              Spot Trading Order
                            </Link>
                            <Link className="dropdown-item" to="/p2pOrder">
                              P2P Trading order
                            </Link>

                            <Link className="dropdown-item" to="/FutureOrders">
                              Future Trading order
                            </Link>

                            <Link className="dropdown-item" to="/exchange/trx-inr">
                              Buy Crypto Order
                            </Link>
                            {/* <Link className="dropdown-item" to="/">
                              Earn Order
                            </Link>
                            <Link className="dropdown-item" to="/">
                              Learn Order
                            </Link> */}
                          </div>
                        </li>

                        {/* <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/SpotOrders" ? "active" : ""
                          }`}
                          to="/SpotOrders"
                        >
                          Order
                        </Link>
                      </li> */}
                      </>
                    ) : null}

                    {!isLoggedIn ? (
                      <li className="nav-item">
                        <Link to="/login" className="nav-link">
                          Sign In
                        </Link>
                      </li>
                    ) : null}
                    {!isLoggedIn ? (
                      <Link to="/create" className="btn btn-primary">
                        Sign Up
                      </Link>
                    ) : null}

                    {isLoggedIn ? (
                      <>
                        <li className="">
                          <div onMouseEnter={handleLinkHover}>
                            <Link to="/Dashboard">
                              <div className="user_avatar cursor_pointer">
                                <span className="">
                                  {profile?.name
                                    ? profile?.name
                                        ?.split("")
                                        .slice(0, 2)
                                        .join("")
                                        ?.toUpperCase()
                                    : profile?.email
                                        ?.split("")
                                        .slice(0, 2)
                                        .join("")
                                        ?.toUpperCase()}
                                </span>
                              </div>
                            </Link>
                          </div>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <Link className="dropdown-item" to="/profile">
                                <div className="dropdown-body-item w-75">
                                  <div className="text-truncate">
                                    <PiUserCircle className="fs-3 text-lgray me-2" />
                                    {profile?.email
                                      ?.split("")
                                      .slice(0, 2)
                                      .join("") +
                                      "***@***" +
                                      profile?.email?.substr(
                                        profile?.email.indexOf(".")
                                      )}
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/Dashboard">
                                <div className="dropdown-body-item">
                                  <span className="">Dashboard</span>
                                </div>
                              </Link>
                            </li>

                            <li>
                              <hr class="dropdown-divider" />
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/AccountSecurity"
                              >
                                <div className="dropdown-body-item">
                                  <span className="">Account Security</span>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/AccountVerification"
                              >
                                <div className="dropdown-body-item">
                                  <span className="">
                                    Identity Verification
                                  </span>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a
                                className="dropdown-item cursor_pointer"
                                onClick={() => {
                                  backRouter();
                                }}
                              >
                                <div className="dropdown-body-item text-danger">
                                  <MdLogout className="fs-5" /> Log Out
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : null}

                    {isLoggedIn ? (
                      <>
                        <li className="nav-item dropdown right_custom_nav custom_drpdwn">
                          <a
                            onMouseEnter={handleDrawerClose}
                            href="#"
                            id="notifications"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div className="nav-link notification_icon">
                              <IoNotificationsOutline className="fs-3" />
                              {/* <span class="noty-manager-bubble">{not_data?.length}</span> */}
                            </div>
                          </a>

                          <div
                            class="dropdown-menu dropdown-menu-end"
                            role="menu"
                            aria-labelledby="notification_dropdown"
                          >
                            <section className="notification-menuwrapper">
                              <div class="pb-3 px-3 border-bottom-0 d-flex justify-content-between align-items-center">
                                <h6 className="mb-0">
                                  <span class="text-danger">{not_data?.length}</span>{" "}
                                  Notifications
                                </h6>
                                <div>
                                  <Link
                                    to="/AllNotification"
                                    className="fs-14 text-gray"
                                  >
                                    See all <LuArrowRight />
                                  </Link>
                                </div>
                              </div>
                              <div className="">
                                <div
                                  id="notification-list"
                                  class="list-group custom_scrollbar"
                                >
                                  {not_data?.length>0 && not_data?.map((item)=>(
                                          <div class="noty-manager-list-item">
                                          <div class="activity-item">
                                            <div className="icon_bg">
                                              <HiOutlineSpeakerphone />
                                            </div>

                                            <div class="activity">
                                             {item?.title}
                                              <div className="subdecription">
                                                {item?.message}
                                              </div>
                                              <span>{getMInutAgo(item?.timestamp)} minutes ago</span>
                                            </div>
                                          </div>
                                          </div>
                                  ))}
                                 
                                  {/* <div class="noty-manager-list-item">
                                    <div class="activity-item">
                                      <div className="icon_bg">
                                        <HiOutlineSpeakerphone />
                                      </div>

                                      <div class="activity">
                                        Task completed
                                        <div className="subdecription">
                                          You have completed the task -Welcome
                                          to bitbse exchange and start trading
                                        </div>
                                        <span>14 minutes ago</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="noty-manager-list-item">
                                    <div class="activity-item">
                                      <div className="icon_bg">
                                        <HiOutlineSpeakerphone />
                                      </div>

                                      <div class="activity">
                                        Task completed
                                        <div className="subdecription">
                                          You have completed the task -Welcome
                                          to bitbse exchange
                                        </div>
                                        <span>14 minutes ago</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="noty-manager-list-item">
                                    <div class="activity-item">
                                      <div className="icon_bg">
                                        <HiOutlineSpeakerphone />
                                      </div>

                                      <div class="activity">
                                        Task completed
                                        <div className="subdecription">
                                          You have completed the task -Welcome
                                          to bitbse exchange
                                        </div>
                                        <span>14 minutes ago</span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </section>
                          </div>
                        </li>
                      </>
                    ) : null}
                    {isLoggedIn ? (
                      <>
                     <div className= "" onClick={swtchTheme}>
                     <i className="fa fa-sun fs-4 text-white" id="theme_icon"></i>
              
              
              
                
              </div> 

                        {/* <div className="theme-setting-box">
            <span className="switch" onClick={swtchTheme} >
              <span onClick={swtchTheme}
                className={`item ${switch_theme === "light" ? "active" : ""}`}
              >
                {switch_theme === "light"?
                <IoSunnyOutline alt="Light Theme" />:<GoMoon alt="Dark Theme" />}
              </span>
            </span>
          </div> */}
                      </>
                    ) : null}
                  </ul>
                </div>

                {/* mobile menu start */}

                <div className="d-block d-lg-none">
                  {!isLoggedIn ? (
                    <>
                      <div className="d-grid gap-3">
                        <Link to="/login" className="btn btn-light">
                          Sign In
                        </Link>

                        <Link to="/create" className="btn btn-primary">
                          Sign Up
                        </Link>
                      </div>
                      <hr class="divider-bottom my-4" />
                    </>
                  ) : null}

                  <ul className="navbar-nav mt-2">
                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/About" ? "active" : ""
                        }`}
                        to="/About"
                      >
                        About
                      </Link>
                    </li>

                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/Markets" ? "active" : ""
                        }`}
                        to="/Markets"
                      >
                        Markets
                      </Link>
                    </li>

                    <li className="nav-item dropdown custom_drpdwn">
                      <a
                        class={`nav-link dropdown-toggle ${
                          isTradeDropdownActive() ? "active" : ""
                        }`}
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Trade
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <div data-bs-dismiss="offcanvas">
                          <Link
                            className="dropdown-item"
                            to="/exchange/trx-inr"
                          >
                            Spot Trade
                          </Link>
                        </div>
                        <div data-bs-dismiss="offcanvas">
                          <Link
                            className="dropdown-item"
                            to="/derivative/trx-inr"
                          >
                            Future Trade
                          </Link>
                        </div>
                        {/* <div data-bs-dismiss="offcanvas">
                          <Link className="dropdown-item" to="/FastTrade">
                            Instant Trade
                          </Link>
                        </div> */}
                        <div data-bs-dismiss="offcanvas">
                          {" "}
                          <Link className="dropdown-item" to="/p2pOrder">
                            P2P Trade
                          </Link>
                        </div>
                      </div>
                    </li>

                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/StakingPlans" ? "active" : ""
                        }`}
                        to="/StakingPlans"
                      >
                        Staking
                      </Link>
                    </li>

                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/AffiliateHome" ? "active" : ""
                        }`}
                        to="/AffiliateHome"
                      >
                        Referral
                      </Link>
                    </li>

                    <li className="nav-item" data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/rewardhub" ? "active" : ""
                        }`}
                        to="/rewardhub"
                      >
                        Rewards
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* theme sidebar */}

      <div
        class="offcanvas offcanvas-end"
        // tabindex="-1"
        id="theme_toggle"
        aria-labelledby="theme_toggle dfjsdjfhsjdfh"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title fw-bold" id="theme_toggle">
            Preferences
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <h3 className="sub_heading fs-14">Layout Style</h3>

          <div className="theme-setting-box">
            <span className="theme-tit">Theme</span>
            <span className="switch" onClick={swtchTheme}>
              <span
                className={`item ${switch_theme === "light" ? "active" : ""}`}
              >
                <IoSunnyOutline alt="Light Theme" />
              </span>
              <span
                className={`item ${switch_theme === "dark" ? "active" : ""}`}
              >
                <GoMoon alt="Dark Theme" />
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* Desktop drawer content */}
      {isDrawerOpen && (
        <div className="drawer" onMouseLeave={handleDrawerClose}>
          <div class="drawer-body ">
            <div className="drawer-menu-container">
              <Link to="/Dashboard">
                <div class="d-flex gap-1 align-items-center p-3">
                  <div class="name_circle">
                    <span className="">
                      {profile?.name
                        ? profile?.name
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            ?.toUpperCase()
                        : profile?.email
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            ?.toUpperCase()}
                    </span>
                  </div>
                  <div>
                    <div className="email_text_width">
                      <h4 className="fs-14 text-truncate mb-1">
                        {profile?.email?.split("").slice(0, 2).join("") +
                          "***@***" +
                          profile?.email?.substr(
                            profile?.email.lastIndexOf(".")
                          )}
                      </h4>
                    </div>
                    <span class="badge bg-soft-theme">Main Account</span>
                  </div>
                  <div>
                    <span class="cursor_pointer"></span>
                  </div>
                  <div class="mx-3"></div>
                </div>
              </Link>
              <div className="px-3">
                <Link className="userprofile_sidbar_nav" to="/AccountSecurity">
                  <span> Account Secuirty</span>
                </Link>
                <Link className="userprofile_sidbar_nav" to="/TradingFee">
                  <span>My Fee rates</span>
                </Link>

                <Link className="userprofile_sidbar_nav" to="/AffiliateHome">
                  <span>Referral Program</span>
                </Link>

                <Link className="userprofile_sidbar_nav" to="/rewardhub">
                  <span>My Rewards</span> 
                  {/* <span class="badge bg-badge">4</span> */}
                </Link>

                <Link className="userprofile_sidbar_nav" to="/ApiManagement">
                  <span>API</span>
                </Link>

                <Link className="userprofile_sidbar_nav" to="/">
                  <span>Settings</span>
                </Link>
                <hr />
              </div>
            </div>

            <div className="logout-wrapper d-grid p-3">
              <a
                className="btn btn-danger"
                onClick={() => {
                  backRouter();
                }}
              >
                <span>Logout</span>
              </a>
            </div>
          </div>
        </div>
      )}

      {/* Mobile drawer content */}

      {isDrawerMobopen && (
        <>
          <div className="drawer">
            <div class="drawer-body">
              <div className="drawer-menu-container">
                <Link to="/profile">
                  <div class="d-flex gap-1 p-3 mt-3">
                    <div class="name_circle">
                      <span className="">
                        {profile?.name
                          ? profile?.name
                              ?.split("")
                              .slice(0, 2)
                              .join("")
                              ?.toUpperCase()
                          : profile?.email
                              ?.split("")
                              .slice(0, 2)
                              .join("")
                              ?.toUpperCase()}
                      </span>
                    </div>
                    <div class="mob_mw_150">
                      <h4 className="fs-14 text-truncate mb-1">
                        {profile?.email?.split("").slice(0, 2).join("") +
                          "***@***" +
                          profile?.email?.substr(
                            profile?.email.lastIndexOf(".")
                          )}
                      </h4>
                      <span class="badge bg-light">Main Account</span>
                    </div>
                    <div>
                      <span class="cursor_pointer"></span>
                    </div>
                    <div class="mx-3"></div>
                  </div>
                </Link>
                <div className="px-3">
                  <Link to="/AccountVerification">
                    <div class="verification_card mb-3">
                      Complete Identity Verification Now <IoMdArrowForward />
                    </div>
                  </Link>

                  <div class="d-flex gap-4 my-2">
                    <Link
                      to="/AssetsDeposit"
                      className="btn btn-primary"
                    >
                      <BsDownload /> Deposit
                    </Link>
                    <Link to="/AssetsWithdraw" className="btn btn-primary">
                      <BsUpload /> Withdrawal
                    </Link>
                  </div>

                  <hr class="divider-bottom p-0" />
                  <Link to="/AllNotification">
                    <div className="d-flex justify-content-between position-relative">
                      <div className="mobile-nav">Notifications </div>
                      <div className="notification_icon">
                        <div className="position-relative">
                          <IoNotificationsOutline className="fs-4" />
                          <span class="noty-bubble fs-10">{not_data?.length}</span>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <div className="">
                    <div
                      className="toggle-btn d-flex justify-content-between cursor_pointer"
                      onClick={toggleDiv}
                    >
                      <div className="mobile-nav"> Assets</div>
                      <div>
                        <span className="toggle-icon">
                          {isOpen ? (
                            <>
                              <TiArrowSortedUp />
                            </>
                          ) : (
                            <>
                              <TiArrowSortedDown />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    {isOpen && (
                      <div>
                        <ul className="list-unstyled ms-2 list_bullets">
                          <li>
                            <Link className="dropdown-item" to="/fundingMain">
                              Spot Trading Wallet
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/p2pFundingMain">
                              P2P Trading Wallet
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/futureFundingMain">
                              Future Trading Wallet
                            </Link>
                          </li>

                          {/* <hr class="dropdown-divider" />

                          <li>
                            <Link className="dropdown-item" to="/MyBonus">
                              Earn
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="">
                    <div
                      className="toggle-btn d-flex justify-content-between cursor_pointer"
                      onClick={toggleOrdermenu}
                    >
                      <div className="mobile-nav"> Orders</div>
                      <div>
                        <span className="toggle-icon">
                          {isOpenorder ? (
                            <>
                              <TiArrowSortedUp />
                            </>
                          ) : (
                            <>
                              <TiArrowSortedDown />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    {isOpenorder && (
                      <div>
                        <ul className="list-unstyled ms-2 list_bullets">
                          <li>
                            <Link className="dropdown-item" to="/SpotOrders">
                              Spot Trading order
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/p2pOrder">
                              P2P Trading order
                            </Link>
                          </li>

                          <li>
                            <Link className="dropdown-item" to="/FutureOrders">
                              Future Trading order
                            </Link>
                          </li>

                          {/* <li>
                            <Link className="dropdown-item" to="/">
                              Buy Crypto Order
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/">
                              Earn Order
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/">
                              Learn Order
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    )}
                  </div>

                  <hr class="divider-bottom p-0" />

                  <Link
                    className="userprofile_sidbar_nav"
                    to="/AccountSecurity"
                  >
                    <span> Account Secuirty</span>
                  </Link>
                  <Link className="userprofile_sidbar_nav" to="/">
                    <span>My Fee rates</span>
                  </Link>

                  <Link className="userprofile_sidbar_nav" to="/AffiliateHome">
                    <span>Referral Program</span>
                  </Link>

                  <Link className="userprofile_sidbar_nav" to="/MyBonus">
                    <span>My Rewards</span>{" "}
                    <span class="badge bg-badge">4</span>
                  </Link>

                  <Link className="userprofile_sidbar_nav" to="/">
                    <span>API</span>
                  </Link>

                  <Link className="userprofile_sidbar_nav" to="/">
                    <span>Settings</span>
                  </Link>
                </div>
              </div>
              <div className="d-grid p-3">
                <a
                  className="btn btn-danger"
                  onClick={() => {
                    backRouter();
                  }}
                >
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="userprofileMobile"
        aria-labelledby="userprofileMobileLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="userprofileMobileLabel"></h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <Link to="/profile" data-bs-dismiss="offcanvas">
            <div class="d-flex gap-1 p-3 mt-3">
              <div class="name_circle">
                <span className="">
                  {profile?.name
                    ? profile?.name
                        ?.split("")
                        .slice(0, 2)
                        .join("")
                        ?.toUpperCase()
                    : profile?.email
                        ?.split("")
                        .slice(0, 2)
                        .join("")
                        ?.toUpperCase()}
                </span>
              </div>
              <div class="mob_mw_150">
                <h4 className="fs-14 text-truncate mb-1">
                  {profile?.email?.split("").slice(0, 2).join("") +
                    "***@***" +
                    profile?.email?.substr(profile?.email.lastIndexOf("."))}
                </h4>
                <span class="badge bg-light">Main Account</span>
              
              </div>
              <div>
                <span class="cursor_pointer"></span>
              </div>
              <div class="mx-3"></div>
            </div>
          </Link>
          <div className="px-3">
            <Link to="/AccountVerification" data-bs-dismiss="offcanvas">
              <div class="verification_card mb-2">
                Complete Identity Verification Now <IoMdArrowForward />
              </div>
            </Link>

            <div class="d-flex gap-4 my-2">
              <Link
                to="/AssetsDeposit"
                className="btn btn-outline-primary"
                data-bs-dismiss="offcanvas"
              >
                <BsDownload /> Deposit
              </Link>
              <Link
                to="/AssetsWithdraw"
                className="btn btn-primary"
                data-bs-dismiss="offcanvas"
              >
                <BsUpload /> Withdrawal
              </Link>
            </div>

            <hr class="divider-bottom p-0" />
            <Link to="/AllNotification">
              <div className="d-flex justify-content-between position-relative">
                <div className="mobile-nav">Notifications </div>
                <div className="notification_icon">
                  <div className="position-relative">
                    <IoNotificationsOutline className="fs-4" />
                    <span class="noty-bubble fs-10">4</span>
                  </div>
                </div>
              </div>
            </Link>

            
            <div className="">
              <div
                className="toggle-btn d-flex justify-content-between cursor_pointer"
                onClick={toggleDiv}
              >
                <div className="mobile-nav"> Assets</div>
                <div>
                  <span className="toggle-icon">
                    {isOpen ? (
                      <>
                        <TiArrowSortedUp />
                      </>
                    ) : (
                      <>
                        <TiArrowSortedDown />
                      </>
                    )}
                  </span>
                </div>
              </div>
              {isOpen && (
                <div>
                  <ul className="list-unstyled ms-2 list_bullets">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/fundingMain"
                        data-bs-dismiss="offcanvas"
                      >
                        Funding Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/fundingMain"
                        data-bs-dismiss="offcanvas"
                      >
                        Unified Trading Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/fundingMain"
                        data-bs-dismiss="offcanvas"
                      >
                        Inverse Derivatives Account
                      </Link>
                    </li>

                    <hr class="dropdown-divider" />

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/MyBonus"
                        data-bs-dismiss="offcanvas"
                      >
                        Earn
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            
            <div className="">
              <div
                className="toggle-btn d-flex justify-content-between cursor_pointer"
                onClick={toggleOrdermenu}
              >
                <div className="mobile-nav"> Orders</div>
                <div>
                  <span className="toggle-icon">
                    {isOpenorder ? (
                      <>
                        <TiArrowSortedUp />
                      </>
                    ) : (
                      <>
                        <TiArrowSortedDown />
                      </>
                    )}
                  </span>
                </div>
              </div>
              {isOpenorder && (
                <div>
                  <ul className="list-unstyled ms-2 list_bullets">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/SpotOrders"
                        data-bs-dismiss="offcanvas"
                      >
                        Spot Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Unlifted Trading order
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Inverse Derivatives Order
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Buy Crypto Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Earn Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Learn Order
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
           
            <hr class="divider-bottom p-0" />

            <Link
              className="userprofile_sidbar_nav"
              to="/AccountSecurity"
              data-bs-dismiss="offcanvas"
            >
              <span> Account Secuirty</span>
            </Link>
            <Link
              className="userprofile_sidbar_nav"
              to="/"
              data-bs-dismiss="offcanvas"
            >
              <span>My Fee rates</span>
            </Link>

            <Link
              className="userprofile_sidbar_nav"
              to="/AffiliateHome"
              data-bs-dismiss="offcanvas"
            >
              <span>Referral Program</span>
            </Link>

            <Link
              className="userprofile_sidbar_nav"
              to="/MyBonus"
              data-bs-dismiss="offcanvas"
            >
              <span>My Rewards</span> <span class="badge bg-badge">4</span>
            </Link>

            <Link
              className="userprofile_sidbar_nav"
              to="/"
              data-bs-dismiss="offcanvas"
            >
              <span>API</span>
            </Link>

            <Link
              className="userprofile_sidbar_nav"
              to="/"
              data-bs-dismiss="offcanvas"
            >
              <span>Settings</span>
            </Link>
          </div>
        </div>
        <div className="d-grid p-3">
          <a
            className="btn btn-danger"
            onClick={() => {
              backRouter();
            }}
            data-bs-dismiss="offcanvas"
          >
            <span>Logout</span>
          </a>
        </div>
      </div> */}
    </>
  );
}

function CoinRow(props) {
  const history = useHistory();
  let price = 0;
  let coinStatus = false;
  switch (props.pairing_currency?.toUpperCase()) {
    case "INR":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number(props.inr_price.toFixed(props.spread_inr))
          : props.current_price
          ? Number(props.current_price.toFixed(props.spread_inr))
          : 0.12
      );

      break;

    case "USDT":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number(props.usdt_price.toFixed(props.spread_usdt))
          : props.current_price
          ? Number(
              (props.current_price / props.pc_price.USDT).toFixed(
                props.spread_usdt
              )
            )
          : 0.03
      );

      break;
    case "BTC":
      price = coinStatus
        ? Number(props.btc_price.toFixed(props.spread_btc))
        : props.current_price
        ? Number(
            (props.current_price / props.pc_price.BTC).toFixed(props.spread_btc)
          )
        : 0.03;
      // console.log("check symbol_2: ", props.symbol, price);
      break;
    case "DTBX":
      price = coinStatus
        ? Number(props.spread_vrx.toFixed(props.spread_vrx))
        : props.current_price
        ? Number(
            (props.current_price / props.pc_price.DTBX).toFixed(
              props.spread_vrx
            )
          )
        : 0.03;
      break;
    default:
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? Number(props.inr_price.toFixed(props.spread_inr))
          : props.current_price
          ? Number(props.current_price.toFixed(props.spread_inr))
          : 0.12
      );
  }
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/${props.type}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/${props.type}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
        // props.closeDropdown();
      }}
    >
      <td>
        <div className="d-flex gap-2 align-items-center--">
          <div className="img-container">
            <img src={props.icon} alt={props.symbol} width="20" height="20" />
          </div>
          <div>
            <h6 className="mb-0 fs-12">
              <span> {props.symbol}</span>
              <span className="text-lgray px-1">/</span>
              <span className="text-lgray">
                {props.pairing_currency?.toUpperCase()}
              </span>
            </h6>
            <span className="text-lgray fs-12">
              {" "}
              {props.name?.toUpperCase()}
            </span>
          </div>
        </div>
      </td>
      {/* <td className="text-end">
        <p className="no-margin text-fade mb-0">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {price}
            </>
          ) : (
            price
          )}
        </p>
      </td> */}
      <td className="text-end">
        <h6 className="no-margin text-fade mb-0 fs-12">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {price}
            </>
          ) : (
            price
          )}
        </h6>
        <span
          className={`fs-12 ${
            props.direction == "up" ? "text-success" : "text-danger"
          }`}
        >
          <i
            className={
              props.direction == "up" ? "fa fa-caret-up" : "fa fa-caret-down"
            }
          ></i>{" "}
          {getRound(props.price_change_percentage_1h)}%
        </span>
      </td>
    </tr>
  );
}

function CoinRowAll(props) {
  const history = useHistory();
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/${props.type}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/${props.type}/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
        // props.closeDropdown();
      }}
    >
      <td>
        <div className="d-flex gap-2 align-items-center--">
          <div className="img-container">
            <img src={props.icon} alt={props.symbol} width="20" height="20" />
          </div>
          <div>
            <h6 className="mb-0 fs-12">
              <span> {props.symbol}</span>
              <span className="text-lgray px-1">/</span>
              <span className="text-lgray">
                {props.pairing_currency?.toUpperCase()}
              </span>
            </h6>
            <span className="text-lgray fs-12">
              {" "}
              {props.name?.toUpperCase()}
            </span>
          </div>
        </div>
      </td>

      {/* <td className="text-end">
        <p className="no-margin text-fade mb-0">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {props.price}
            </>
          ) : (
            props.price
          )}
        </p>
      </td> */}

      <td className="text-end">
        <h6 className="no-margin text-fade mb-0 fs-12">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {props.price}
            </>
          ) : (
            props.price
          )}
        </h6>

        <span
          className={`fs-12 ${
            props.direction == "up" ? "text-success" : "text-danger"
          }`}
        >
          <i
            className={
              props.direction == "up" ? "fa fa-caret-up" : "fa fa-caret-down"
            }
          ></i>
          {getRound(props.price_change_percentage_1h)}%
        </span>
      </td>
    </tr>
  );
}
